
import React from 'react';
import { authService } from 'shared/services';
import { SSO } from '../../app-setting';
export class LoginSSOComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                username: '',
                password: '',
            },
            loading: false,
            errorMessage: '',
        };
        this.ngOnInit = this.ngOnInit.bind(this);
    }
    componentDidMount() {
        
        this.ngOnInit()
    }
    ngOnInit() {

        const handle  = this.props.location.search
        const params = new URLSearchParams(handle); 
        this.ticket = params.get('ticket');
        this.loginsso();
      }
     handlerError(error) {
        if (error) {
          switch (error) {
            case 'INVALID_TICKET':
              //this.loginError = 'Đăng nhập bị từ chối, vui lòng kiểm tra lại';
              window.location.href = SSO.URL_LOGOUT_SSO
              break;
            case 'INVALID_USER':
              this.props.history.push({ pathname: '/unauthorized' });
              //this.loginError = 'Tài khoản không tồn tại. Vui lòng liên hệ quản trị hệ thống';
              break;
            default:
              this.loginError = error;
              break;
          }
          this.setState({ errorMessage: this.loginError });
        }
      }

    loginsso() {
        this.loading = true;
        
        const key = 'countTicket';
        let countTicket = localStorage.getItem(key);
        if (countTicket === null) {
            countTicket = '1';
            localStorage.setItem(key, countTicket);
        }
        let obj =
        {
            ticket: this.ticket,
            serivceurl: SSO.WEB_ENDPOINT_SSO,
        }
        const loginSubcsription = authService.loginsso(obj)
        .subscribe(data => {
          countTicket = '1';
          localStorage.setItem(key, countTicket);
          this.props.history.push({ pathname: '/admin' });
          this.loading = false;
          
        },
          error => {
            if (error.error === 'INVALID_TICKET') {
              let countTicketCurrent = +(localStorage.getItem(key));
              if (countTicketCurrent <= 10) {
                  countTicketCurrent = +countTicketCurrent + 1;
                  localStorage.setItem(key, countTicketCurrent.toString());
                  window.location.href = SSO.URL_REDIRECT_SSO
               }
            }
            console.log(error);
            this.handlerError(error.error);
            this.loading = false;
          });
          setTimeout(() => {
            loginSubcsription.unsubscribe();
            this.loading = false;
          }, 20000);
    }

    render() {
        return (
          <div className="page-loading text-center ">
            <div className="sk-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
            </div>
            <label className="text-danger">{this.state.errorMessage}</label>
           </div>
        );
      }
}
