import { tap } from 'rxjs/operators';
import { CLIENT } from '../../app-setting';
import { http } from '../utils';
import { store } from 'redux/store';
import { OauthAction } from 'redux/actions';
class AuthService {
    constructor(_dispatch, { setToken, removeToken, getToken }) {
        this.dispatch = _dispatch;
        this.action = {
            setToken,
            removeToken,
            getToken
        };
    }
    login(data) {        
        const body = {
            //grant_type: 'password',
            email: data.username,
            password: data.password,
            //client_id: CLIENT.client_id,
            //client_secret: CLIENT.client_secret,
        };
        console.log(this.action);
        return http.post('api/login', body).pipe(tap((res) => {
            this.dispatch(this.action.setToken(res));
        }));
    }
    loginsso(data) {
        const body = {
            grant_type: 'sso',
            ticket: data.ticket,
            serivceurl: data.serivceurl,
            client_id: CLIENT.client_id,
            client_secret: CLIENT.client_secret,
        };
        console.log(this.action);
        return http.post('api/token/auth', body).pipe(tap((res) => {
            this.dispatch(this.action.setToken(res));
        }));
    }
    getTree() {
        return http.get('api/menumanager/treemenu/');
    }
    logout() {
        this.dispatch(this.action.removeToken());
    }
}
const authService = new AuthService(store.dispatch, OauthAction);
export { authService, AuthService };
