import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { API_URL } from 'app-setting';
class FileUploadViewComponent extends React.Component {
    static propTypes = {
        value: PropTypes.array, // value truyền vào
        label: PropTypes.string, // value truyền vào
    };
    constructor(props) {
        super(props);
        this.state = {
            files: props.value, // value hiện tại
            isShow: false,
            fileView: {},
        };
        this.subscriptions = {};
    }

    handleFileChange(e) {
        this.props.onFileChange(e);
    }
    componentDidMount() {

    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
    viewFile(item) {
        this.setState({ isShow: true, fileView: item });
    }
    // onError(e) {
    //     logger.logError(e, 'error in file-viewer');
    //   }
    handleClose() {
        this.setState({ isShow: false });
    }

    render() {
        const { label, value,
            ...props } = this.props;
        const { files, fileView } = this.state;
        const docs = [
            { uri: `${API_URL}${fileView.url}` },
            //{ uri: '../../../../src/assets/images/abc.pdf' },
        ];
        const filePath = `${API_URL}${fileView.url}`;
        const fileType = fileView.type;
        
        const filePathView = `${API_URL}${fileView.urlView}`;
       
        const fileFormat = fileView.format;
        return (
            <React.Fragment>
                {/*<Form.Label>*/}
                {/*    {*/}
                {/*        label ? label : "Đính kèm"*/}
                {/*    }*/}
                {/*</Form.Label>*/}
                {
                    value ? <ol className="lstfileUpload">
                        {
                            value.map((file, index) => {
                                return (
                                    <li key={index}>
                                        <span>{file.title}</span> | <a title="Tải về" target="_blank" href={`${API_URL}${file.url}`}><i className="fa fa-download"></i></a> | <a title="Xem trước" href="#" onClick={() => { this.viewFile(file); }}><i className="fa fa-eye"></i></a>
                                    </li>
                                )
                            })
                        }
                    </ol> : ''
                }
                <Modal size="lg" keyboard={false} backdrop="static" show={this.state.isShow} onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Xem trước file {fileView.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} md="12">
                                {
                                    (fileFormat === '.jpg' || fileFormat === '.jpeg' || fileFormat === '.png' || fileFormat === '.icon') ?
                                        <img width="100%" height="100%" src={filePathView}></img> :
                                        (fileFormat === '.docx' || fileFormat === '.doc' || fileFormat === '.xls' || fileFormat === '.xlsx') ?
                                            <iframe className={fileType} width="100%" height="600" frameborder="0" src={`https://docs.google.com/gview?url=${filePathView}&embedded=true`}></iframe> :
                                            (fileFormat === '.pdf') ?
                                                <div className="pdf-viewer">
                                                    <embed
                                                        src={filePathView}
                                                        type="application/pdf"
                                                        width="100%"
                                                        height="500px"
                                                    />
                                                </div>
                                                : "Không hỗ trợ hiển thị file"

                                }


                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>
                    <Modal.Footer className="text-center">
                        <Button variant="default" className="btn-sm" onClick={() => { this.handleClose(); }}>
                            Đóng
                                </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment >
        );
    }
}
export { FileUploadViewComponent };
