import React from 'react';
import { LoadingComponent } from 'shared/components';
import { _HomeService } from 'modules/home/HomeService';
export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null,
        };
        this.subscriptions = {};

    }
    componentDidMount() {
        this.setState({ loading: true });
        _HomeService.getFooterContentHome().subscribe(res => {
            this.setState({ data: res.data, loading: false });
        });

    }
    render() {
        let {data}=this.state;
        return (
            <div id="footer">
                <LoadingComponent loading={this.state.loading}></LoadingComponent>
                <div className="container">
                    <div className="row pb-2">
                        <div className="col-md-12 pt-3 footer-padding">
                            <div className="footer-1">
                                <div className="contact-info" dangerouslySetInnerHTML={{ __html: data?.hotline }}>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 pt-3 footer-padding">
                            <p className="text-white text-right">Phát triển bởi <a className="text-white" target="_blank" href="http://hcmedia.com.vn/">HCmedia</a></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
