import React from 'react';
import { BehaviorsContext } from 'shared/services';
import { Switch } from 'react-router-dom';
import { RouteWithSubRoutes } from 'shared/components';
// import '@iconify/iconify';
// import '@fortawesome/fontawesome-free/css/all.css';
// import 'react-datepicker/dist/react-datepicker.css';
import 'styles/styles.scss';
import "jquery/src/jquery";
import { Context } from 'modules/quantrihethong/users/usersService';
import { HeaderComponent } from './Header';
import Footer from './Footer';
import Menu from './Menu';
import { routes } from './MainRoutes';
class HomeLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false
        };
        this.handlerClickToggle = this.handlerClickToggle.bind(this);
    }
    handlerClickToggle() {
        this.setState({
            toggle: !this.state.toggle
        });
    }
    render() {
        return (
            <BehaviorsContext.Consumer>
                {
                    ({ beh }) => (
                        <Context.Provider value={{
                            beh: beh
                        }} >
                            <div className="wrapper">                                
                                <HeaderComponent />
                                <Menu />
                                <div id="wrapper-content">
                                    <Switch>
                                        {routes.map((route, i) => (
                                            <RouteWithSubRoutes key={i} {...route} />
                                        ))}

                                    </Switch>
                                </div>
                                <Footer />
                            </div>
                        </Context.Provider>
                    )
                }
            </BehaviorsContext.Consumer>
        );
    }
}
export { HomeLayout };



