import * as Constant from './ThanhVienConstant';
const ThanhVien = {
    ThanhVienList: [],
    meta: {
        page: 1,
        page_size: 15,
        sort: { id: 'desc' },
        search: '',
        filter: {
            ma: '',
            tenDayDu: '',
            capTrenId: 0,
        },
    },
    ThanhVienList_Con: [],
    meta_con: {
        page: 1,
        page_size: 15,
        sort: { id: 'desc' },
        search: '',
        filter: {
            ma: '',
            tenDayDu: '',
            capTrenId: 0,
        },
    },
    ThanhVienList_Vo: [],
    meta_vo: {
        page: 1,
        page_size: 15,
        sort: { id: 'desc' },
        search: '',
        filter: {
            ma: '',
            tenDayDu: '',
            capTrenId: 0,
        },
    },

};
const ThanhVienReducer = (state = ThanhVien, action) => {
    switch (action.type) {
        case Constant.THANHVIEN_SET_LIST_DATA:
            return Object.assign({}, state, { ThanhVienList: action.ThanhVienList });
        case Constant.THANHVIEN_SET_LIST_META:
            return Object.assign(
                {},
                state,
                {
                    meta: Object.assign(
                        {},
                        state.meta,
                        action.meta
                    )
                });
        case Constant.THANHVIEN_SET_LIST_DATA_CON:
            return Object.assign({}, state, { ThanhVienList_Con: action.ThanhVienList_Con });
        case Constant.THANHVIEN_SET_LIST_META_CON:
            return Object.assign(
                {},
                state,
                {
                    meta: Object.assign(
                        {},
                        state.meta_con,
                        action.meta_con
                    )
                });
        case Constant.THANHVIEN_SET_LIST_DATA_VO:
            return Object.assign({}, state, { ThanhVienList_Vo: action.ThanhVienList_Vo });
        case Constant.THANHVIEN_SET_LIST_META_VO:
            return Object.assign(
                {},
                state,
                {
                    meta: Object.assign(
                        {},
                        state.meta_vo,
                        action.meta_vo
                    )
                });
        default:
            return state;
    }
};
export { ThanhVienReducer };