import React from 'react';
import { Link } from 'react-router-dom';
import logoImage from 'assets/images/aptistests_logo.png';
import "../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import { LoadingComponent } from 'shared/components';
import { _HomeService } from 'modules/home/HomeService';
export default class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: null,
        };
        this.subscriptions = {};

    }
    componentDidMount() {
        this.setState({ loading: true });
        _HomeService.getTopNav().subscribe(res => {
            this.setState({ data: res.data, loading: false });
        });

        _HomeService.getTopHead().subscribe(res => {
            this.setState({ topHead: res.data, loading: false });
        });

    }
    render() {
        let { data, topHead } = this.state;

        return (
            <div id="menu">
                <LoadingComponent loading={this.state.loading}></LoadingComponent>
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        {/* <a className="navbar-brand" href="/">
                            <img src={topHead?.logo} alt="Aptis ESOL" />
                        </a> */}
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav text-uppercase font-14">
                                <li className="nav-item active">
                                    <a className="nav-link main-color" href="/"><i className="fa fa-home" /></a>
                                </li>
                                {data && data.length > 0 && data.map((item, index) => {
                                    return (<li key={index} className="nav-item "><a className="nav-link main-color" href={item.seo_name}>{item.title}</a>
                                    </li>);
                                })}

                            </ul>
                        </div>
                    </nav>
                </div>

            </div>
        );
    }
}
