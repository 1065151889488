import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import { http } from 'shared/utils';
import { Formik } from 'formik';
import classNames from 'classnames';
import { flattenTree, setValue } from './utils';
import { API_URL } from 'app-setting';
const fileImage = ['jpg', 'jpeg', 'png', 'icon'];
const fileDocument = ['docx', 'doc', 'xls', 'xlsx', 'pdf'];
const fileAll = ['docx', 'doc', 'xls', 'xlsx', 'jpg', 'ipeg', 'png', 'icon', 'pdf'];
//const fileAll = ['docx', 'doc', 'xls', 'xlsx', 'jpg', 'ipeg', 'png', 'icon', 'pdf', 'mp4'];
const maxsize = 50;
class FileAttachComponent extends React.Component {
    static propTypes = {
        multiple: PropTypes.bool, // chọn nhiều file
        name: PropTypes.string,
        action: PropTypes.oneOf(['new', 'edit', 'read']), // truyền vào state.action
        size: PropTypes.number, // dung lượng cho phép MB
        label: PropTypes.string, // label field
        btnName: PropTypes.string, // tên button thêm tệp
        value: PropTypes.array, // value truyền vào
        // định dạng cho phép nhập dạng string "fileImage","fileDocument","fileAll" 
        // hoặc nhập thằng định dạng cho phép theo dạng mảng ['doc','xls'...]
        fileAllow: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        onFileChange: PropTypes.func,
        onFileDelete: PropTypes.func,

    };
    constructor(props) {
        super(props);
        let fileType = [];
        if (typeof props.fileAllow === 'string') {
            switch (props.fileAllow) {
                case 'fileImage':
                    fileType = fileImage;
                    break;
                case 'fileDocument':
                    fileType = fileDocument;
                    break;
                case 'fileAll':
                    fileType = fileAll;
                    break;
                default:
                    fileType = fileAll;
                    break;
            }
        } else {
            fileType = props.fileAllow;
        }
        let prevProps = this.props;
        this.state = {
            innerValue: props.value, // value hiện tại
            checkedit: false,
            fileType: fileType,
            maxSize: props.size,
            sumSize: 0,
            fileAttach: PropTypes.array,
            fileDelete: PropTypes.array,
            lstIdDelete: [],
            prevProps: prevProps
        };
        this.subscriptions = {};
    }
    static getDerivedStateFromProps(props, state) {
        let prevProps = { ...state.prevProps };
        let nextState = null;
        if (props.value !== prevProps.value) {
            nextState = nextState || {};
            prevProps = props || {};
            nextState.innerValue = props.value;
            state.checkedit = true;
        }
        if (props.action !== prevProps.action) {
            nextState = nextState || {};
            prevProps = props || {};
            nextState.checkedit = props.action === 'edit';
        }
        if (props.fileAllow !== prevProps.fileAllow) {
            nextState = nextState || {};
            if (typeof props.fileAllow === 'string') {
                switch (props.fileAllow) {
                    case 'fileImage':
                        state.fileType = fileImage;
                        break;
                    case 'fileDocument':
                        state.fileType = fileDocument;
                        break;
                    case 'fileAll':
                        state.fileType = fileAll;
                        break;
                    default:
                        state.fileType = fileAll;
                        break;
                }
            } else {
                nextState.fileType = props.fileAllow;
            }
        }
        if (props.size !== prevProps.size) {
            nextState = nextState || {};
            if (props.size > 0) {
                nextState.maxSize = props.size;
            }
            else {
                nextState.maxSize = maxsize;
            }
        }
        if (nextState) {
            nextState.prevProps = prevProps;
        }
        return nextState;
    }
    handleFileChange(e) {
        this.props.onFileChange(e);
    }
    componentDidMount() {
        //console.log(this.props);
    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
    upload() {
        document.getElementById(this.props.name ? this.props.name : 'selectImage').click()
    }
    // xoa file => gán lại state
    deleteAtt(e) {


        if (e.size && this.state.sumSize > 0)
            this.state.sumSize = this.state.sumSize - e.size;
        console.log(this.state.sumSize);
        let { lstIdDelete } = this.state;
        var array = [...this.state.innerValue];
        var index = array.indexOf(e);
        if (index !== -1) {
            // đã lưu vào db thì sẽ thêm trường isDelete để post đi
            let fileDelete = new Array();
            if (array[index].id > 0) {
                lstIdDelete.push(array[index].id);
                array[index].isDelete = 1;
                fileDelete.push(array[index].id);
            } else { // nếu chưa lưu vào db thì chỉ cần xóa ở state là dc                
                array.splice(index, 1);
            }

            let arrRe = new Array();
            array.map(x => {
                if ((!x.error || x.error && x.error.length == 0) && !x.isDelete)
                    arrRe.push(x)
            });
            this.setState({
                innerValue: array,
                fileAttach: arrRe,
                lstIdDelete: lstIdDelete
            });
            if (this.props.onFileDelete) {
                this.props.onFileDelete(lstIdDelete);
            }
            this.props.onFileChange(arrRe);
        }

    }
    checkFileType(filetype) {
        if (this.state.fileType.indexOf(filetype.toLowerCase()) > -1)
            return '';
        else
            return 'Định dạng không được phép upload';
    }
    checkSize(size) {
        this.state.sumSize = this.state.sumSize + size;
        console.log(this.state.sumSize);
        if ((this.state.maxSize * 1000000) >= this.state.sumSize)
            return '';
        else
            return 'Tổng dung lượng các file vượt quá cho phép';
    }
    onChangeHandler = event => {
        let files = event.target.files;
        // check filetype
        let arr = Array.from(files).map(x => {
            x.filetype = x.name.split('.').pop();
            x.title = x.name;
            x.error = new Array();
            if (this.checkFileType(x.name.split('.').pop()) !== '')
                x.error.push(this.checkFileType(x.name.split('.').pop()));
            let checksizef = this.checkSize(x.size);
            if (checksizef !== '')
                x.error.push(checksizef);
            return x;
        });
        // nếu sửa thì add thêm file vào các file cũ
        if (this.props.action == 'edit' && this.state.innerValue) {
            this.state.innerValue.forEach(element => {
                arr.push(element);
            });
        }
        // nếu đã có file , check xem file da ton tai chua, neu ton tai thì replace file cu
        if (this.props.action == 'new' && this.state.innerValue) {
            // for arr, neu ma inner value neu mà co element thì delete trc khi add lai 
            for (var i = 0; i < arr.length; i++) {
                for (var j = 0; j < this.state.innerValue.length; j++) {
                    if (arr[i].name == this.state.innerValue[j].name) {
                        delete this.state.innerValue[j];
                    }
                }
            }
            //arr.map(x => {
            //    if (this.state.innerValue.indexOf(x) > -1)
            //        delete this.state.innerValue[this.state.innerValue.indexOf(x)];
            //})
            this.state.innerValue.forEach(element => {
                arr.push(element);
            });
        }

        let arrRe = new Array();
        arr.map(x => {
            if (!x.error || x.error && x.error.length == 0)
                arrRe.push(x)
        });
        this.setState({
            innerValue: arr,
            fileAttach: arrRe,
        });
        this.props.onFileChange(arrRe);
    }

    render() {
        const {
            multiple,
            name,
            action,
            size,
            label,
            btnName,
            value,
            onChange,
            // eslint-disable-next-line no-unused-vars
            onBlur,

            ...props } = this.props;
        let { fileType } = this.state;
        let labelFileType = '';
        if (fileType) {
            for (let i = 0; i < fileType.length; i++) {
                if (i == 0)
                    labelFileType += fileType[i];
                else
                    labelFileType += ' | ' + fileType[i];
            }
        }
        return (
            <div className="fileattachment">
                <React.StrictMode>
                    <Form.Label>
                        {
                            label ? label : ''
                        }
                        <span style={{ 'color': 'red', 'fontSize': '12px', 'fontWeight': '400' }}>
                            (Định dạng cho phép {labelFileType})
                        </span>
                    </Form.Label><br />
                    {(() => {
                        if (action === 'new') {
                            return (<React.Fragment>
                                <Button size="sm" className="ml-2" type="button" variant="success" onClick={() => { this.upload(); }}>
                                    <span className="iconify" data-icon="fa-solid:plus" data-inline="false" />
                                    {btnName ? btnName : 'Đính kèm'}
                                </Button>
                                <input id={name ? name : 'selectImage'} hidden type="file" onChange={this.onChangeHandler} multiple={multiple} />
                                <ul>
                                    {this.state.innerValue ?
                                        this.state.innerValue.map((file, index) => {
                                            return (
                                                (!file.isDelete || file.isDelete === 0) ?
                                                    <li className="li_FileAttach" key={index}>
                                                        {
                                                            (!file.error || file.error && file.error.length === 0) ?
                                                                <React.Fragment>
                                                                    <a href="#">{file.title}</a>
                                                                    <span onClick={() => this.deleteAtt(file)}>
                                                                        <i className="fa fa-times text-danger">
                                                                        </i>
                                                                    </span>
                                                                </React.Fragment>
                                                                : <React.Fragment>
                                                                    <p style={{ margin: '0px' }}>
                                                                        {file.title}
                                                                        <span onClick={() => this.deleteAtt(file)}>
                                                                            <i className="fa fa-times text-danger">
                                                                            </i>
                                                                        </span></p>
                                                                    <small style={{ color: 'red' }}>{file.error.map((err, index) => {
                                                                        return <span key={index}>{err}; </span>
                                                                    })}</small>
                                                                </React.Fragment>
                                                        }

                                                    </li>
                                                    : ''
                                            )
                                        })
                                        : ''
                                    }
                                </ul>
                            </React.Fragment>)
                        } else if (action === 'edit') {
                            return (<React.Fragment>
                                {/* <Button variant="success" className="ml-2" type="button" style={{ marginLeft: 'auto' }} onClick={this.upload}><span className="iconify fa" data-icon="fa-solid:plus" data-inline="false"></span> {btnName ? btnName : "Đính kèm"}</Button> */}
                                <Button size="sm" className="ml-2" type="button" variant="success" onClick={() => { this.upload(); }}>
                                    <span className="iconify" data-icon="fa-solid:plus" data-inline="false" />
                                    {btnName ? btnName : 'Đính kèm'}
                                </Button>
                                <input id={name ? name : 'selectImage'} hidden type="file" onChange={this.onChangeHandler} multiple={multiple} />
                                <ul>
                                    {this.state.innerValue ?
                                        this.state.innerValue.map((file, index) => {
                                            //return (<li className="li_FileAttach" key={index}>{file.title} <a href="#" onClick={() => this.deleteAtt(file)}><span className="fa fa-times text-danger"></span></a></li>)
                                            return (
                                                (!file.isDelete || file.isDelete === 0) ?
                                                    <li className="li_FileAttach" key={index}>
                                                        {
                                                            (!file.error || file.error && file.error.length === 0) ?
                                                                <React.Fragment>
                                                                    <a href={`${API_URL}${file.url}`} target="_blank">{file.title}</a>
                                                                    <span onClick={() => this.deleteAtt(file)}>
                                                                        <i className="fa fa-times text-danger">
                                                                        </i>
                                                                    </span>
                                                                </React.Fragment>
                                                                : <React.Fragment>
                                                                    <p style={{ margin: '0px' }}>{file.title}
                                                                        <span onClick={() => this.deleteAtt(file)}>
                                                                            <i className="fa fa-times text-danger">
                                                                            </i>
                                                                        </span>
                                                                    </p>
                                                                    <small style={{ color: 'red' }}>{file.error.map((err, index) => {
                                                                        return <span key={index}>{err}; </span>
                                                                    })}</small>
                                                                </React.Fragment>
                                                        }

                                                    </li>
                                                    : ''
                                            )
                                        })
                                        : ''
                                    }
                                </ul>
                            </React.Fragment>)
                        } else {
                            return (<React.Fragment>
                                <ul>{
                                    value ?
                                        value.map((file, index) => {
                                            return (
                                                <li className="li_FileAttach" key={index} style={{ listStyleType: 'none' }}>
                                                    <a href={`${API_URL}${file.url}`} target="_blank">{file.title}</a>
                                                </li>);
                                        }) : ''
                                }
                                </ul>
                            </React.Fragment>);
                        }
                        //return(<h1>abc</h1>)
                    })()
                    }
                </React.StrictMode >
            </div>);
    }
}
export { FileAttachComponent };
