import React from 'react';
import { TreeView } from './TreeViewComponent';
import { authService } from 'shared/services';
import Background from 'assets/images/sidebar-bg.png'
export default class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.state = {
            data: []
        };
    }
    componentDidMount() {
        //var groupmenu = window.sessionStorage.getItem("groupmenu");
        document.addEventListener('click', this.handleClick);
        authService.getTree().subscribe(res => {
            this.setState({ data: res });
        });
    }

    componentWillUnmount() {
        // khi unmount phải hủy listen click out side
        document.removeEventListener('click', this.handleClick);
    }

    handleClick = (event) => {
        const { target } = event;
        //kiểm tra xem nếu click bên trong sidebar và giao diện mobile thì ẩn sidebar
        if (this.wrapperRef.current.contains(target)) {
            let width = window.innerWidth;
            if (width <= 992 && this.props.onClick) {
                this.props.onClick(target);
            }
        }
    }
    render() {
        return (
            <aside ref={this.wrapperRef} className="main-sidebar" >

                {/* <Link to="/admin" className="brand-link">
                    <Image src={logo} alt="Tổng cục quản lý thị trường" className="brand-image img-circle elevation-3" />
                    <span className="brand-text font-weight-light">Tổng cục quản lý thị trường</span>
                </Link>
                */}
                {/*<div style={{ backgroundImage: `url(${imageTH})`}}>
                </div>*/}
                <div className="sidebar scrollbar scrollbar-dark" style={{ marginLeft: 0, backgroundImage: `url(${Background})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 146%", padding: 2 }}>
                    <nav className="nav-main_sideBar" >
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

                            {this.state.data.map((item, index) => {
                                return (
                                    <TreeView key={index} data={item} />
                                );
                            })}
                        </ul>
                    </nav>

                </div>

            </aside>
        );
    }
}
