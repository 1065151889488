import { combineReducers } from 'redux';
import { oauthReducer } from './oauth/oauthReducer';
import { menuhethongReducer as menuhethong } from './menuhethong/menuhethongReducer'; 
import { groupsReducer as groups} from './quantrihethong/groups/groupsReducer';
import { permissionsReducer as permissions} from './quantrihethong/permissions/permissionsReducer';
import { rolesReducer as roles} from './quantrihethong/roles/rolesReducer';
import { usersReducer as users} from './quantrihethong/users/usersReducer';
import { documentsReducer as documents} from './quantrihethong/documents/documentsReducer';
import { logsReducer as logs } from './quantrihethong/logs/logsReducer';
import { DanhSachDanhMucReducer as DanhSachDanhMuc } from './danhsachdanhmuc/DanhSachDanhMucReducer';
import { TaiLieuReducer as TaiLieu } from './tailieu/TaiLieuReducer';
import { CauHinhTaiLieuReducer as CauHinhTaiLieu } from './cauhinhtailieu/CauHinhTaiLieuReducer';
import { QuanLyDanhMucReducer as QuanLyDanhMuc } from './quanlydanhmuc/QuanLyDanhMucReducer';
import { DanhMucReducer as DanhMuc } from './danhmuc/DanhMucReducer';
import { ConfigsReducer as Configs } from './configs/ConfigsReducer';
import { HinhAnhReducer as HinhAnh } from './hinhanh/HinhAnhReducer';


import {ConfigExcelReducer as ConfigExcel} from './configexcel/ConfigExcelReducer';
import { CauHinhDanhMucReducer as CauHinhDanhMuc } from './quanlydanhmuc/cauhinhdanhmuc/CauHinhDanhMucReducer';
import { ThongTinHoSoReducer as ThongTinHoSo } from './thongtinhoso/ThongTinHoSoReducer';
import { ConfigsObjectSendReducer as ConfigsObjectSend } from './configsobjectsend/ConfigsObjectSendReducer';
import { TrinhDoReducer as TrinhDo } from './trinhdo/TrinhDoReducer';
import { QuocGiaReducer as QuocGia } from './danhmuc/quocgia/QuocGiaReducer';
import { DongHoReducer as DongHo } from './danhmuc/dongho/DongHoReducer';
import { ThanhVienReducer as ThanhVien } from './thanhvien/ThanhVienReducer';
//{0}

const reducer = combineReducers({
    oauth: oauthReducer,
    menuhethong,
    permissions,
    groups,    
    roles,
    users,
    documents,
    logs,
        DanhSachDanhMuc,
    TaiLieu,
    CauHinhTaiLieu,
    QuanLyDanhMuc,
    DanhMuc,
    Configs,
    ConfigExcel,
    CauHinhDanhMuc,
    ThongTinHoSo,
    ConfigsObjectSend,
    TrinhDo,
    QuocGia,
    DongHo,
    ThanhVien,
    HinhAnh,
//{1}

});
export default reducer;