import { createContext } from 'react';
import { BaseService } from 'shared/services';
import { Subject } from 'rxjs';
import { http } from 'shared/utils';
import { map } from 'rxjs/operators';
class HomeService extends BaseService {
    constructor(props) {
        const _props = Object.assign({}, { url: 'api/home' }, props);
        super(_props);
        this.sendToForm = new Subject();
    }
    getTopHead() {
        return http.get('api/top-head');
    }
    getTopNav() {
        return http.get('api/top-nav');
    }
    getSliderHome() {
        return http.get('api/slider-home');
    }
    getAboutHome() {
        return http.get('api/about-home');
    }
    getExamLocationHome() {
        return http.get('api/exam-location-home');
    }
    getAptisWhyHome() {
        return http.get('api/aptis-why-home');
    }
    getStepAptisHome() {
        return http.get('api/step-aptis-home');
    }
    getNewsHome() {
        return http.get('api/news-home');
    }
    getPartnerHome() {
        return http.get('api/partner-home');
    }
    getFooterContentHome() {
        return http.get('api/footer-content');
    }
    getAdsContentHome() {
        return http.get('api/home-ads');
    }
    getDanhSachCauHoi() {
        return http.get('api/tests').pipe(map((res) => {
            return res;
        }));
    }

}
const _HomeService = new HomeService();
const Context = createContext();
export { Context, _HomeService, HomeService };