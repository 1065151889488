import React, { Component } from 'react';
import { BehaviorsContext } from '../../services';
import { Toast } from 'react-bootstrap';
class ToastComponent extends Component {
    static contextType = BehaviorsContext;
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            title:'',
            msg: '',
            type: 'success',
            delay:3000
        };
        this.subscriptions = {};
    }
    componentDidMount() {
        this.subscriptions['sendToToast'] = this.context.beh.sendToToast.subscribe(res => {
            if (!this.state.show) {
                this.setState({
                    show: true,
                    msg: res.msg || '',
                    title: res.title || 'Thông báo',
                    type: res.type || 'success',
                    delay: res.delay || 3000
                });
            }
        });
    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
    setShow(){
        this.setState({
            show: false            
        });
    }
    render() {
        return (
            <Toast className={`toast-alert ${this.state.type}`} onClose={() => this.setShow()} show={this.state.show} delay={this.state.delay} autohide>
                {/* <Toast.Header>
                    <strong className="mr-auto">{this.state.title}</strong>
                </Toast.Header> */}
                <Toast.Body>{this.state.msg}</Toast.Body>
            </Toast>
        );
    }
}

export default ToastComponent;