import * as Constant from './logsConstant';

const logs = {
    logsList: [],
    meta: {
        page: 1,
        page_size: 15,
        sort: {Created:'desc'},
        search: '',
        filter: {       
            type:3
        },     
    }
};
const logsReducer = (state = logs, action) => {
    switch (action.type) {
    case Constant.LOGS_SET_LIST_DATA:
        return Object.assign({}, state, { logsList: action.logsList });
    case Constant.LOGS_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {logsReducer};