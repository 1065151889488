
import React from 'react';
import {  Button } from 'react-bootstrap';
export class UnauthorizedComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                username: '',
                password: '',
            },
            loading: false,
            errorMessage: '',
        };
    }
    componentDidMount() {
    }
    linkHome() {
        this.props.history.push({ pathname: '/admin' });
    }

    render() {
        return (
            <body style={{background: '#607D8B', color: '#fff', fontFamily:'Lato', textAlign: 'center', height:'800px'}}>
                <div className="page-wrap" style={{paddingTop: '100px'}}>
                <h1 style={{fontSize: '7em'}}>401</h1>
                <h2 style={{fontSize: '4em'}}>UNAUTHORIZED ERROR</h2>
                <p><Button style={{fontSize: '1em',color: '#fff'}}  onClick={() => this.linkHome()}>HOME</Button></p>
                </div>
            </body>
          
        );
      }
}
