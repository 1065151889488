import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { API_URL } from 'app-setting';
import UserImg from 'assets/images/avatar.jpg';
import { http } from 'shared/utils';
import { Context, _HinhAnhService } from './HinhAnhService';
import { ModalImageComponent } from './ModalImageComponent';

class AnhDaiDienComponent extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    editMode: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    isValid: PropTypes.bool,
    isInvalid: PropTypes.bool,
    value: PropTypes.string,
    onBlur: PropTypes.func,

  };
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      loading: false,
    };
    this.subscriptions = {};
    this.myInput = React.createRef();
  }
  triggerClick = () => {
    if (this.props.editMode == false) {
      return;
    }
    else {
      _HinhAnhService.sendToForm.next({
        action: 'choose',
        isShow: true
      });
    }
    // this.myInput.current.click();
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value != this.props.value) {
      this.setState({ value: this.props.value });
    }
  }
  componentDidMount() {

  }
  handleClose(res) {
    this.setState({value:res});
    if (this.props.onChange) {
      this.props.onChange({
        persist: () => { },
        target: {
          type: 'change',
          id: this.props.id || null,
          name: this.props.name,
          value: res
        }
      });
    }
    console.log(res);
  }

 
  render() {
    let { value } = this.state;
    let { className, editMode } = this.props;
    return (
      <>
        <div className='text-center p-3 avatar' >
          <input accept='image/jpeg,image/png,image/jpg' multiple={true} ref={this.myInput} type="file" onChange={e => this.handleChangeImg(e)} style={{ display: 'none' }} />
          <Image onClick={this.triggerClick} src={value ? API_URL + value : UserImg} className={className} alt="Ảnh đại diện người dùng" />
          {
            editMode && <span title='Chọn ảnh' className='camera' onClick={this.triggerClick} ><i className='fa fa-camera'></i></span>
          }
        </div>
        <ModalImageComponent onClose={this.handleClose.bind(this)} />
      </>
    );
  }
}
export { AnhDaiDienComponent };