import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { formatDateTime } from 'shared/utils';
import DatePicker from 'react-datepicker';

class DatePickerComponent extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.number]),
    };
    constructor(props) {
        super(props);
        this.state = {            
        };
        this.subscriptions = {};
    }
    static getDerivedStateFromProps() {
        
    }
    handleFileChange(e) {
        this.props.onFileChange(e);
    }
    componentDidMount() {
        console.log(this.props);
    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
    upload() {
        document.getElementById("selectImage").click()
    }
       
    onChange() {
        //let res = selectedNodes.map(x => {
        //    return x.value;
        //});
        //let value = null;
        //if (['radioSelect', 'simpleSelect'].includes(this.props.mode)) {
        //    value = res[0] || null;
        //} else {

        //    value = res || null;
        //}
        //if (this.state.innerValue !== value) {
        //    setValue(this.state.innerValue, this.state.map, this.props.mode, false);
        //    setValue(value, this.state.map, this.props.mode, true);
        //}
        //this.setState({ innerValue: value }, () => {
        //    if (this.props.onChange) {
        //        this.props.onChange({
        //            persist: () => { },
        //            target: {
        //                type: 'change',
        //                id: this.props.id || null,
        //                name: this.props.name,
        //                value: value
        //            }
        //        });
        //    }

        //});
    }
    handleBlur() {
        // this.setState({timeBlur:new Date()});
    }
    render() {
        const {           
            value,
            //onChange,
            errors,
            //onBlur,
            touched } = this.props;
        return (
            <React.StrictMode>
                <Field name="ngaycapmst"
                    className={'form-control' +
                        (errors.ngaycapmst && touched.ngaycapmst ? ' is-invalid' : '')}
                    onChange={()=> this.onChange()}
                    value={formatDateTime(value.ngaycapmst)}
                    dateFormat="dd/MM/yyyy"
                    component={DatePicker}
                    onBlur={this.handleBlur.bind(this)}
                    isInvalid={touched.ngaycapmst && !!errors.ngaycapmst}>
                </Field>
            </React.StrictMode>
        );
    }
}
export { DatePickerComponent };
