import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Button, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import { Context, _HinhAnhService } from './HinhAnhService';
import { Page } from 'shared/components';
import { HinhAnhAction } from 'redux/actions';
import { http } from 'shared/utils';
import './style.scss';
import { Image } from 'react-bootstrap';
import { API_URL } from 'app-setting';
import { toast } from 'react-toastify';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { PagingComponent } from './PagingComponent';
import { LoadingComponent } from 'shared/components';

//import { HinhAnhFormComponent } from '../form/HinhAnhFormComponent';

class HinhAnhList extends Component {
    static propTypes = {
        history: PropTypes.object,
        location: PropTypes.object,
        setData: PropTypes.func,
        setMeta: PropTypes.func,
        data: PropTypes.array,
        meta: PropTypes.object,
        onSelect: PropTypes.func,
        folder: PropTypes.object,
    }
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            check: false,
            meta: {},
            loading: false,
            data: [],
            lstFolders: [],
            isShow: false,
            action: 'choose',
            folder: props.folder,
            keySearch: '',
            isAddNew: false,
            isEdit: false,
            files: [],
            point: { x: 0, y: 0 },
            fileName: '',
            extension: '',
            idEdit: null
        };
        this.subscriptions = {};
        this.handleChangeImg = this.handleChangeImg.bind(this);
        this.myInput = React.createRef();
    }
    componentDidMount() {
        this.fetchData(this.props.meta);
    }
    handleChoose(that, item) {
        if (that.props.onSelect) {
            that.props.onSelect(item.url);
        }
    }
    handleEdit(that, item) {
        let arr = item.fileName.split('.');
        that.setState({ isEdit: true, fileName: arr[0], extension: arr[1], idEdit: item.id });

    }
    processHanleChange(page)
    {
        let {meta}=this.props;
        meta.page=page;
        this.fetchData(meta);
    }
    handleDelete(that, item) {
        return http.delete('pbimages/' + item.id).subscribe(() => {
            toast.success('Xóa thành công');
            let {meta}=this.props;
            meta.page=1;
            this.fetchData(meta);
        });
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.folder.id != this.props.folder.id) {
            this.setState({ folder: this.props.folder });
            let {meta}=this.props;
            meta.page=1;
            this.fetchData(meta);
        }
    }

    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
    fetchData(meta) {
        meta.filter.FolderId = this.props.folder.id;
        meta.search = this.state.keySearch;
        this.onCloseForm();
        this.onCloseFormEdit();
        this.setState({ loading: true });
        _HinhAnhService.getMany(meta).subscribe(res => {    
            this.props.setData(res.data || []);
            this.props.setMeta({
                page: res.currentPage,
                page_size: res.pageSize,
                total: res.totalRows
            });
            this.setState({ loading: false, data: res.data||[] });
        }, err => {
            console.log(err);
            this.setState({ loading: false });
        });
    }
    handleChange(res) {
        _HinhAnhService.handleChange(res, this);
    }
    handleChangeImg(e) {
        let files = [];
        for (var i = 0; i < e.target.files.length; i++) {
            var fileExtension = e.target.files[i].name.split('.').pop(); //"pdf"
            if (fileExtension == 'jpg' || fileExtension == 'jpeg' ||
                fileExtension == 'jfif' || fileExtension == 'pjpeg' || fileExtension == 'pjp' || fileExtension == 'png') {
                files.push(e.target.files[i]);
            }
            else {
                alert('Vui lòng chọn đúng định dạng file (ví dụ: jpg,jpeg,png,...)');
                return;
            }
        }
        this.setState({ files });
    }

    onOpenForm = () => {
        this.setState({ isAddNew: true, files: [] });
    }
    onCloseForm = () => {
        this.setState({ isAddNew: false, files: [] });
    }
    onCloseFormEdit = () => {
        this.setState({ isEdit: false, fileName: '', extension: '' });
    }
    viewDetail(item) {
        _HinhAnhService.sendToForm.next({
            id: item.id,
            action: 'read',
            isShow: true
        });
    }
    viewEdit(item) {
        _HinhAnhService.sendToForm.next({
            id: item.id,
            action: 'edit',
            isShow: true
        });
    }
    async delete(item) {
        _HinhAnhService.DeleteAction(item, this);
    }

    handleClickSeachBtn() {
        this.fetchData(this.props.meta);
    }
    handleClick = (e, item) => {
        if (e.detail == 2) {
            if (this.props.onSelect) {
                this.props.onSelect(item.url);
            }
        }
    }
    triggerClick = () => {
        this.myInput.current.click();
    };
    saveForm = () => {
        let { files, folder } = this.state;
        if (files && files.length > 0) {
            let formData = new FormData();
            files.forEach(element => {
                formData.append('files', element);
            });
            this.setState({ loading: true });
            const headers = {
                'Content-Type': 'multipart/form-data',
            };
            http.post('pbimages/avatar/' + folder.id, formData, { headers: headers }).subscribe(() => {
                let meta=this.props.meta;
                meta.page=1;
                this.fetchData(meta);
                this.setState({ loading: false });
                this.onCloseForm();
            }, err => {
                console.log();
                toast.error(err.error);
                this.setState({ loading: false });
            });

        }
        else {
            alert('Bạn chưa chọn file');
            return;
        }

    }
    saveFormEdit = () => {
        let { fileName, extension, idEdit } = this.state;
        if (fileName && extension && idEdit) {
            return http.put('pbimages/' + idEdit, { fileName: fileName + '.' + extension, id: idEdit }).subscribe(() => {
                this.fetchData(this.props.meta);
                this.setState({ loading: false });
                this.onCloseFormEdit();
            }, err => {
                console.log(err);
                this.setState({ loading: false });
            });
        }
        else {
            alert('Tên file không được để trống');
            return;
        }

    }
    render() {
        let { files } = this.state;
        let {page, page_size, total}=this.props.meta;
        return (
            <Page>
                <Page.Content>
                    <Card>
                        <Card.Body>
                        <LoadingComponent loading={this.props.loading}/>
                            {
                                this.state.isAddNew && <Row className='form-image'>
                                    <Col sm={8} md={6}>
                                        <input accept='image/jpeg,image/png,image/jpg' multiple={true} ref={this.myInput} type="file" onChange={e => this.handleChangeImg(e)} style={{ display: 'none' }} />
                                        <Button variant="default" className='mr-2' size="sm" onClick={this.triggerClick} >
                                            <i className="fa fa-plus mr-1" aria-hidden="true"></i>Chọn file
                                        </Button>
                                        <ul>
                                            {
                                                files && files.length > 0 && files.map((file, index) => {
                                                    return (<li key={index}>{file.name}</li>);
                                                })
                                            }
                                        </ul>
                                    </Col>
                                    <Col>
                                        <div className="float-sm-right">
                                            <Button variant="default" className='mr-2' size="sm" onClick={this.saveForm}>
                                                <i className="fa fa-upload mr-1" aria-hidden="true"></i>
                                                Tải lên
                                            </Button>
                                            <Button variant="default" size="sm" onClick={this.onCloseForm} >
                                                <i className="fa fa-times mr-1" aria-hidden="true"></i>
                                                Đóng
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {
                                this.state.isEdit && <Row className='form-image'>
                                    <Col sm={8} md={6}>
                                        <input type='text' placeholder='Tên file' value={this.state.fileName} className='form-control' onChange={(e) => { this.setState({ fileName: e.target.value }) }}></input>
                                    </Col>
                                    <Col sm={2}>
                                        <span>.{this.state.extension}</span>
                                    </Col>
                                    <Col>
                                        <div className="float-sm-right">
                                            <Button variant="success" className='mr-2' size="sm" onClick={this.saveFormEdit}>
                                                <i className="fa fa-save mr-1" aria-hidden="true"></i>
                                                Lưu lại
                                            </Button>
                                            <Button variant="default" size="sm" onClick={this.onCloseFormEdit} >
                                                <i className="fa fa-times mr-1" aria-hidden="true"></i>
                                                Hủy
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            <Row className="grid-view-action-bar">
                                <Col sm={6} md={5}>
                                    <InputGroup size="sm" className="filter">
                                        <FormControl
                                            type="text"
                                            name="keySearch"
                                            placeholder="Tìm kiếm"
                                            aria-label="Search"
                                            autoFocus
                                            value={this.state.keySearch}
                                            onChange={(ev) => { this.setState({ keySearch: ev.target.value }); }}
                                        />
                                        <InputGroup.Append className="btn-filter-basic" onClick={() => { this.handleClickSeachBtn(); }}>
                                            <Button variant="primary">
                                                <span className="iconify" data-icon="fa-solid:search" data-inline="false" />
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <div className="float-sm-right">
                                        <Button variant="primary" size="sm" onClick={this.onOpenForm} >
                                            <span className="iconify fa" data-icon="fa-solid:plus" data-inline="false"></span>
                                            Thêm mới
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col className='root-image'>
                                    {
                                        (this.state.data && this.state.data.length > 0) ?
                                            <>
                                                <ul className='library-image'>
                                                    {
                                                        this.state.data.map((item, index) => {
                                                            return (
                                                                <li key={index} className={'item'} onClick={(e) => this.handleClick(e, item)}>
                                                                    <ContextMenuTrigger id={`imageid_${item.id}`} >
                                                                        <Image src={API_URL + item.url} alt={item.fileName} />
                                                                        <div className='name'>{item.fileName}</div>
                                                                    </ContextMenuTrigger>
                                                                    <ContextMenu id={`imageid_${item.id}`}>
                                                                        <MenuItem onClick={() => this.handleChoose(this, item)}>
                                                                            Chọn
                                                                        </MenuItem>
                                                                        <MenuItem divider />
                                                                        <MenuItem data={{ item: item }} onClick={() => this.handleEdit(this, item)}>
                                                                            Đổi tên
                                                                        </MenuItem>
                                                                        <MenuItem divider />
                                                                        <MenuItem data={{ item: item }} onClick={() => this.handleDelete(this, item)}>
                                                                            Xóa
                                                                        </MenuItem>
                                                                    </ContextMenu>
                                                                </li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                                <PagingComponent
                                                    page={page}
                                                    page_size={page_size}
                                                    total={total}
                                                    handleChange={(res) => {
                                                        this.processHanleChange(res);
                                                    }}
                                                />
                                            </>
                                            :
                                            <h6 className='mt-3'>Không có dữ liệu</h6>

                                    }
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                    {/* <QuocGiaFormComponent onClose={this.handleClose.bind(this)} /> */}
                </Page.Content >
            </Page >

        );
    }
}
const mapStateToProps = (state) => {
    return {
        data: state.HinhAnh.HinhAnhList,
        meta: state.HinhAnh.meta
    };
};
const HinhAnhListComponent = connect(mapStateToProps, HinhAnhAction)(HinhAnhList);
export { HinhAnhListComponent };
