import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Row, Col } from 'react-bootstrap';
const defaultPageOptions = {
    page: 1,
    page_size: 15,
    total: 0,
    pageSizeOptions: [15, 30, 50, 100]
};

class Paging extends Component {
    static propTypes = {
        page: PropTypes.number,
        page_size: PropTypes.number,
        total: PropTypes.number,
        pageSizeOptions: PropTypes.array,
        handleChange: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = {
            page: props.page || defaultPageOptions.page,
            page_size: props.page_size || defaultPageOptions.page_size,
            total: props.total || defaultPageOptions.total,
            pageSizeOptions: defaultPageOptions.pageSizeOptions,
            prevProps: props,
        };
        this.inputSelectPage = React.createRef();
    }
    componentDidMount() {

    }
    static getDerivedStateFromProps(props, state) {
        let prevProps = { ...state.prevProps };
        let nextState = null;
        if (prevProps.page !== props.page) {
            nextState = nextState || {};
            nextState.page = prevProps.page = props.page || defaultPageOptions.page;

        }
        if (prevProps.page_size !== props.page_size) {
            nextState = nextState || {};
            nextState.page_size = prevProps.page_size = props.page_size || defaultPageOptions.page_size;
        }
        if (prevProps.total !== props.total) {
            nextState = nextState || {};
            nextState.total = prevProps.total = props.total || defaultPageOptions.total;
        }
        if (prevProps.pageSizeOptions !== props.pageSizeOptions) {
            nextState = nextState || {};
            nextState.pageSizeOptions = prevProps.pageSizeOptions = props.pageSizeOptions || defaultPageOptions.pageSizeOptions;
        }
        if (nextState) {
            nextState.prevProps = prevProps;
        }
        return nextState;

    }
    changePage(num, totalPage){
        let { page} = this.state;
        if(num==page||num<=0||num>totalPage)
            return false;
        else
        {
            if(this.props.handleChange){
                this.props.handleChange(num);
            }
        }
    }

    renderPaging(page, totalPage) {
        return (
            <>
                {/* Back */}
                <li className={`page-item ${page == 1 ? 'disabled' : ''}`} onClick={() => this.changePage(page-1, totalPage)} >
                    <a className="page-link" href="javascript:;"><i className="fa fa-step-backward" aria-hidden="true"></i></a>
                </li>

                {/* Số trang */}
                {
                    <li className={`page-item ${page == 1 ? 'active' : ''}`} onClick={() => this.changePage(1, totalPage)}>
                        <a className="page-link" href="javascript:;">1</a>
                    </li>
                }
                {
                    (page - 2 > 1) &&
                    <li className={'page-item disabled'}>
                        <a className="page-link" href="javascript:;">...</a>
                    </li>
                }
                {
                    (page - 1 > 1) &&
                    <li className={'page-item'} onClick={() => this.changePage(page-1, totalPage)}>
                        <a className="page-link" href="javascript:;">{page - 1}</a>
                    </li>
                }
                {
                    (page!=1&&page!=totalPage) &&
                    <li className={'page-item active'}>
                        <a className="page-link" href="javascript:;">{page}</a>
                    </li>
                }
                {
                    (page + 1 < totalPage) &&
                    <li className={'page-item'} onClick={() => this.changePage(page+1, totalPage)}>
                        <a className="page-link" href="javascript:;">{page + 1}</a>
                    </li>
                }
                {
                    (page + 2 < totalPage) &&
                    <li className={'page-item disabled'}>
                        <a className="page-link" href="javascript:;">...</a>
                    </li>
                }
                <li className={`page-item ${page == totalPage ? 'active' : ''}`} onClick={() => this.changePage(totalPage, totalPage)}>
                    <a className="page-link" href="javascript:;">{totalPage}</a>
                </li>
                {/* Next */}
                <li className={`page-item ${page == totalPage ? 'disabled' : ''}`} onClick={() => this.changePage(page+1, totalPage)}>
                    <a className="page-link" href="javascript:;"><i className="fa fa-step-forward" aria-hidden="true"></i></a>
                </li>
            </>
        );

    }



    render() {
        let { total, page_size, page } = this.state;
        let totalPage = Math.ceil(this.state.total / this.state.page_size) || 1;
        var to = 0;
        var from = 0;
        if (total > 0) {
            from = (page - 1) * page_size + 1;
            to = page * page_size > total ? total : page * page_size;
        }
        return (
            <Row>
                <Col md={6}>
                    {`Hiển thị ${from} đến ${to} trên ${total} bản ghi`}
                </Col>
                <Col md={6} className="text-right d-flex flex-row-reverse">
                    {
                        totalPage > 1 &&
                        <nav aria-label="...">
                            <ul className="pagination">
                                {this.renderPaging(page, totalPage)}

                            </ul>
                        </nav>
                    }


                </Col>
            </Row>


        );
    }
}
const PagingComponent = Paging;
export { PagingComponent };
