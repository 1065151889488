import * as Constant from './CauHinhDanhMucConstant';
const CauHinhDanhMuc = {
    CauHinhDanhMucList: [],
    meta: {
        page: 1,
        page_size: 15,
        sort: {stt:'asc'},
        search: '' ,
        filter: {
          quanLyDanhMucId:0,
danhMucId:0,
        },
    }
};
const CauHinhDanhMucReducer = (state = CauHinhDanhMuc, action) => {
    switch (action.type) {
        case Constant.CAUHINHDANHMUC_SET_LIST_DATA:
            return Object.assign({}, state, { CauHinhDanhMucList: action.CauHinhDanhMucList });
        case Constant.CAUHINHDANHMUC_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { CauHinhDanhMucReducer};