import { http } from '../utils';
import { store } from 'redux/store';
import { string, number } from 'yup';
import { API_URL } from 'app-setting';
import { toast } from 'react-toastify';
import { map } from 'rxjs/operators';
import moment from 'moment';
const pdfContentType = 'application/pdf';


const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr(`data:${pdfContentType};base64,`.length);
    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);
    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }
    return new Blob([out], { type: pdfContentType });
};
class BaseService {
    constructor(props = {}) {
        this.url = props ? props.url : '';
        this.http = http;
    }
    getMany(props, urlfix, strquery) {

        const { page, page_size, sort, search, filter } = props;
        let sortBy = '';
        let queryobj = {};
        let sortDesc = false;
        let title = search; // do api chua co tim kiem theo tu khoa nen de tam search se tim kiem theo username
        if (sort) {
            sortBy = Object.keys(sort)[0];
            sortDesc = sort[sortBy] === 'desc';
        }

        if (strquery != undefined) {
            let params = new URLSearchParams(strquery);

            for (const key of params.keys()) {
                if (params.getAll(key).length > 1) {
                    queryobj[key] = params.getAll(key);
                } else {
                    queryobj[key] = params.get(key);
                }
            }
        }


        const params = Object.assign({}, {
            page: page,
            itemsPerPage: page_size,
            sortBy: sortBy,
            sortDesc: sortDesc,
            FilterText: title
        }, filter, queryobj);

        return http.get(urlfix ? urlfix : `${this.url}`, { params: params });

    }
    export(props, apirouter, strquery) {
        const { sort, search, filter } = props;
        let sortBy = '';
        let queryobj = {};
        let sortDesc = false;
        let title = search; // do api chua co tim kiem theo tu khoa nen de tam search se tim kiem theo username
        if (sort) {
            sortBy = Object.keys(sort)[0];
            sortDesc = sort[sortBy] === 'desc';
        }

        if (strquery != undefined) {
            let params = new URLSearchParams(strquery);

            for (const key of params.keys()) {
                if (params.getAll(key).length > 1) {
                    queryobj[key] = params.getAll(key);
                } else {
                    queryobj[key] = params.get(key);
                }
            }
        }


        const params = Object.assign({}, {
            page: 1,
            itemsPerPage: -1,
            sortBy: sortBy,
            sortDesc: sortDesc,
            FilterText: title
        }, filter, queryobj);
        window.open(`${API_URL}api/configexcel` + '/' + apirouter + '?' + this.getUrlparams(params), '_blank');


    }
    getById(id, urlfix) {
        //return http.get(`${this.url}/${id}`);
        return http.get((urlfix ? urlfix : `${this.url}`)+'/'+id);
    }
    getByUrlPath(url) {
        return http.get(`${this.url}/${url}`);
    }
    create(obj, urlfix) {
        return http.post(urlfix ? urlfix : `${this.url}`, obj);
    }
    postWithUrl(obj, urlfix) {
        return http.post(urlfix ? urlfix : `${this.url}`, obj);
    }
    update(obj, id) {
        return http.put(`${this.url}/${id}`, obj);
    }
    addLogAcess(url) {
        return http.post(`api/logs/access${url}`);
    }
    createFormData(obj, files, objcreate) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        formData.append(objcreate, JSON.stringify(obj));
        return http.post(`${this.url}`, formData, {
            headers: headers
        });
    }
    updateFormData(obj, files, id, objupdate) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        formData.append(objupdate, JSON.stringify(obj));
        return http.put(`${this.url}/${id}`, formData, {
            headers: headers
        });
    }
    del(id) {
        return http.delete(`${this.url}/${id}`);
    }
    dels(ids) {
        return http.delete(`${this.url}/deletes/${ids}`);
    }
    formatDateTime(strValue) {
        if (!strValue) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate();
            var output = (day >= 10 ? '' : '0') + day + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            if (output === '01/01/1')
                return '';
            return output;
        }
    }
    isDate(_date) {
        const _regExp = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
        return _regExp.test(_date);
    }
    isDateddMMYYYY(_date) {
        const _regExp = new RegExp(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/);
        return _regExp.test(_date);
    }
    formatDateTimeTyping(strValue) {


        var output = '';
        if (this.isDate(strValue)) {

            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate();
            output = (day >= 10 ? '' : '0') + day + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            if (output === '01/01/1')
                return '';

        } else
            output = strValue;


        return output;


    }
    formatTime(strValue) {
        if (strValue === undefined || strValue === '' || strValue === null) {
            return '';
        }
        else {
            let output = '';
            let d = new Date(strValue);
            let month = d.getMonth() + 1;
            let day = d.getDate();
            let minutes = d.getMinutes();
            let hours = d.getHours();
            output = (hours >= 10 ? '' : '0') + hours + ':' + (minutes >= 10 ? '' : '0') + minutes + ' ' + (day >= 10 ? '' : '0') + day + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            if (output === '01/01/1')
                return '';
            return output;
        }

    }
    formatDateTimeDate(d) {
        if (d === null) {
            return '';
        }
        var month = d.getMonth() + 1;
        var day = d.getDate();
        var output = (day >= 10 ? '' : '0') + day + '/' +
            (('' + month).length < 2 ? '0' : '') + month + '/' +
            d.getFullYear();
        if (output === '01/01/1')
            return '';
        return output;

    }
    formatDateTimeMDY(strValue) {
        if (strValue === null || strValue === '') {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
            var output = (('' + month).length < 2 ? '0' : '') + month + '/' +
                day + '/' +
                d.getFullYear();
            if (output === '1/01/1')
                return '';
            return output;
        }
    }
    formatDateTimeMDYdate(d) {
        if (d === null) {
            return '';
        }

        var month = d.getMonth() + 1;
        var day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
        var output = (('' + month).length < 2 ? '0' : '') + month + '/' +
            day + '/' +
            d.getFullYear();
        if (output === '1/01/1')
            return '';
        return output;

    }
    /**
     * Check quyền người dùng
     * @param {any} strObjectPer: mã đối tượng
     * @param {any} strPrivilege: mã quyền
     */
    checkPermisson(strObjectPer, strPrivilege) {

        var oauth = store.getState().oauth;
        var lstRoles = oauth.lstRoles;
        if (oauth.IsAdministrator) {
            return true;
        }
        if (lstRoles.length > 0) {
            for (var i = 0; i < lstRoles.length; i++) {
                if (lstRoles[i] === (strObjectPer + '-' + strPrivilege))
                    return true;
            }
        }
        return false;
    }
    formatFullDateTime(strValue) {
        if (strValue === undefined || strValue === '' || strValue === null) {
            return '';
        }
        else {
            let output = '';
            let d = new Date(strValue);
            let month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1);
            let day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
            let hour = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
            let minute = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
            let second = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();
            output = day + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            if (hour > 0) {
                output = output + ' ' + hour + ':' + minute + ':' + second;
            }
            if (output === '1/01/1')
                return '';
            return output;
        }
    }
    //ChageFormat datetime yyyy-mm-dd phục vụ tìm kiếm mở rộng
    changeFormatDateTime(strValue) {
        if (strValue === null) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate();

            var output = d.getFullYear() + '-' +
                (('' + month).length < 2 ? '0' : '') + month + '-' +
                (('' + day).length < 2 ? '0' : '') + day;
            return output;
        }
    }
    formartPriceVND(strNumber) {
        return strNumber ? strNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : strNumber;
    }
    // dels(listId = []): Observable<any> {
    //     //return this.http.post<any>(this.url + '/deletes', listId);
    // }

    getStringByArray(arr) {
        var str = '';
        if (!arr) {
            return '';
        }
        else {
            for (var i = 0; i < arr.length; i++) {
                if (i === 0) {
                    str += arr[i];
                }
                else {
                    str += ', ' + arr[i];
                }
            }
            return str;
        }
    }
    createwithfile(data, obj) {

        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        const formData = new FormData();
        if (data && data.fileAttachs) {
            for (let i = 0; i < data.fileAttachs.length; i++) {
                formData.append('files', data.fileAttachs[i]);
            }
        }
        formData.append(obj, JSON.stringify(data));
        return http.post(`${this.url}`, formData, {
            headers: headers
        });
    }

    updatewithfile(data, obj, func) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        const formData = new FormData();
        if (data && data.fileAttachs) {
            for (let i = 0; i < data.fileAttachs.length; i++) {
                formData.append('files', data.fileAttachs[i]);
            }
        }
        formData.append(obj, JSON.stringify(data));
        if (!func)
            func = '';
        return http.put(`${this.url}${func}/${data.id ? data.id : data.Id}`, formData, {
            headers: headers
        });
    }
    updateDynamicWithFile(data, obj, func) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        const formData = new FormData();
        if (data && data.fileAttachs) {
            for (let i = 0; i < data.fileAttachs.length; i++) {
                formData.append('files', data.fileAttachs[i]);
            }
        }

        formData.append(obj, JSON.stringify(data));
        if (!func)
            func = '';
        return http.put(`${this.url}${func}/${data.id}`, formData, {
            headers: headers
        });
    }
    trimString(obj) {
        return Object.keys(obj).reduce((acc, curr) => {
            if (typeof obj[curr] === 'string' && obj[curr] != null)
                acc[curr] = obj[curr].trim();

            else
                acc[curr] = obj[curr];
            return acc;
        }, {});

    }
    lowerCaseStartChar(str) {
        if (str)
            return str[0].toLowerCase() + str.substring(1);


    }
    getDataEnum(enumName) {
        return http.get(`${this.url}/getenumselect?_Type=` + enumName);

    }

    removeAccents(str) {
        var AccentsMap = [
            'aàảãáạăằẳẵắặâầẩẫấậ',
            'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
            'dđ', 'DĐ',
            'eèẻẽéẹêềểễếệ',
            'EÈẺẼÉẸÊỀỂỄẾỆ',
            'iìỉĩíị',
            'IÌỈĨÍỊ',
            'oòỏõóọôồổỗốộơờởỡớợ',
            'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
            'uùủũúụưừửữứự',
            'UÙỦŨÚỤƯỪỬỮỨỰ',
            'yỳỷỹýỵ',
            'YỲỶỸÝỴ'
        ];
        for (var i = 0; i < AccentsMap.length; i++) {
            var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
            var char = AccentsMap[i][0];
            str = str.replace(re, char);
        }
        return str;
    }
    getTenVietLien(str) {
        var tenkodau = this.removeAccents(str);
        return tenkodau.replace(/[^a-zA-Z0-9_]/g, '');
    }
    getSchemaDynamic(loaiTaiLieuFields, data, schema, isNotRequired) {

        loaiTaiLieuFields.forEach((product) => {


            switch (product.kieuDuLieu) {
                //number
                case 1:
                    schema.fields[product.tenTruong] = number().nullable().integer('Bạn phải nhập kiểu số nguyên');
                    if (product.isRequired && !isNotRequired) {
                        schema.fields[product.tenTruong] = schema.fields[product.tenTruong].required(product.label + ' không được để trống');

                    }
                    if (product.min == 0 || product.min)
                        schema.fields[product.tenTruong] = schema.fields[product.tenTruong].min(product.min, 'Bạn phải nhập số lớn hơn ' + product.min);
                    if (product.max == 0 || product.max)
                        schema.fields[product.tenTruong] = schema.fields[product.tenTruong].max(product.max, 'Bạn phải nhập số nhỏ hơn ' + product.max);

                    schema._nodes.push(product.tenTruong);
                    break;
                case 5:
                    schema.fields[product.tenTruong] = number().nullable();
                    if (product.isRequired && !isNotRequired) {
                        schema.fields[product.tenTruong] = schema.fields[product.tenTruong].required(product.label + ' không được để trống');

                    }
                    if (product.min == 0 || product.min)
                        schema.fields[product.tenTruong] = schema.fields[product.tenTruong].min(product.min, 'Bạn phải nhập số lớn hơn ' + product.min);
                    if (product.max == 0 || product.max)
                        schema.fields[product.tenTruong] = schema.fields[product.tenTruong].max(product.max, 'Bạn phải nhập số nhỏ hơn ' + product.max);

                    schema._nodes.push(product.tenTruong);
                    break;
                //text
                case 2:
                    schema.fields[product.tenTruong] = string().trim().nullable();
                    if (product.isRequired && !isNotRequired)
                        schema.fields[product.tenTruong] = schema.fields[product.tenTruong].required(product.label + ' không được để trống');

                    if (product.maxlength > 0)
                        schema.fields[product.tenTruong] = schema.fields[product.tenTruong].max(product.maxlength, 'Bạn nhập tối đa ' + product.maxlength + ' ký tự');
                    else
                        schema.fields[product.tenTruong] = schema.fields[product.tenTruong].max(250, 'Bạn nhập tối đa 250 ký tự');

                    schema._nodes.push(product.tenTruong);

                    break;
                //textarea
                case 3:
                    schema.fields[product.tenTruong] = string().trim().nullable();
                    if (product.isRequired && !isNotRequired)
                        schema.fields[product.tenTruong] = schema.fields[product.tenTruong].required(product.label + ' không được để trống');

                    if (product.maxlength > 0)
                        schema.fields[product.tenTruong] = schema.fields[product.tenTruong].max(product.maxlength, 'Bạn nhập tối đa ' + product.maxlength + ' ký tự');
                    schema._nodes.push(product.tenTruong);
                    break;

                //trường check box
                case 4:
                    //if (product.isRequired) {
                    //    schema.fields[product.tenTruong] = string().nullable().required(product.label + " không được để trống");
                    //    schema._nodes.push(product.tenTruong);
                    //}

                    break;

                //dattime
                case 6:
                    schema.fields[product.tenTruong] = string().nullable();
                    if (product.isRequired && !isNotRequired) {
                        schema.fields[product.tenTruong] = schema.fields[product.tenTruong].required(product.label + ' không được để trống').test('notplace', product.label + ' không được để trống',
                            function (value) {


                                if (value == '__/__/____')
                                    return false;
                                return true;
                            }
                        );


                    }
                    schema.fields[product.tenTruong] = schema.fields[product.tenTruong].test('datevalide', 'Ngày tháng không hợp lệ',
                        function (value) {
                            console.log(value);

                            if (!value || value == '__/__/____' || !value.includes('_') && (moment(value, 'DD/MM/YYYY')._isValid
                                || moment(value, 'YYYY-MM-DD[T]HH:mm:ss[Z]')._isValid
                            ))
                                return true;
                            return false;
                        }
                    );
                    schema._nodes.push(product.tenTruong);

                    break;
                //danh mục, selec danhmuc
                case 7:
                    if (product.isRequired && !isNotRequired) {
                        schema.fields[product.tenTruong] = number().nullable().required(product.label + ' không được để trống');
                        schema._nodes.push(product.tenTruong);
                    }

                    break;
                default:
                    break;
            }

        });
        console.log(data);

    }
    getLookUpTitle(item) {
        if (item && item.includes(';#'))
            return item.split(';#')[1];
        return '';
    }
    getUrlparams(obj) {
        var str = '';
        for (var key in obj) {
            if (str != '') {
                str += '&';
            }
            str += key + '=' + encodeURIComponent(obj[key]);
        }
        return str;
    }
    getConfigExcelByType(type, loai) {
        return http.get(`api/configexcel/getbytype/${type}/${loai}`).pipe(map((res) => {
            if (res.length > 0) {
                if (res[0].urlTemplate)
                    res[0].urlTemplate = API_URL + res[0].urlTemplate;
                return res[0];

            }
            else
                return {};
        }));
    }
    //hamf xoa chung cannb
    async DeleteAction(item, component) {

        if (await component.context.beh.confirm('Bạn có muốn xóa bản ghi này')) {
            component.setState({
                loading: true
            });
            this.del(item.id).subscribe(() => {
                toast.success('Xóa thành công');
                if (component.props.data.length === 1 && component.props.meta.page !== 1) {
                    component.props.setMeta({ page: component.props.meta.page - 1 });
                }
                component.fetchData(component.props.meta);
            },
                (err) => {
                    component.setState({
                        loading: false
                    });

                    toast.error(err.error);
                });
            component.setState({
                loading: false
            });
        }
    }

    onKeyPress(ev) {
        const keyCode = ev.keyCode || ev.which;
        if (keyCode === 13) {
            ev.preventDefault();
            return false;
        }
    }
    componentDidMount(component) {

        if (!component.state.metaDefault && Object.keys(component.state.metaDefault).length > 0) {
            component.props.setMeta({ search: '', filter: component.state.metaDefault });

        }


    }
    handleChange(res, component) {

        if (!component.state.metaDefault || Object.keys(component.state.metaDefault).length == 0) {
            component.setState({ metaDefault: component.props.meta });

        }
        //cannb ham xu ly chung

        let newMeta = {};
        switch (res.event) {
            case 'changeSelected':
                break;

            case 'changeKeySearchThuGon':
                newMeta = Object.assign({}, component.props.meta, res.data);
                component.setState({ check: false });
                component.props.setMeta({ search: newMeta.search });
                //component.fetchData(newMeta);

                break;
            case 'changePageSize': res.data.page = 1;
                newMeta = Object.assign({}, component.props.meta, res.data);
                if (component.props.meta.page_size !== newMeta.page_size) {
                    // component.props.setMeta({ page_size: newMeta.page_size });
                    component.fetchData(newMeta);
                }
                break;
            case 'huyTimKiemMoRong':

                newMeta = Object.assign({}, component.state.metaDefault, res.data);
                component.setState({ search1: newMeta.search, check: true });
                component.props.setMeta({ filter: newMeta.filter });
                component.fetchData(newMeta);
                break;
            case 'OpenTimKiemMoRong':

                newMeta = Object.assign({}, component.state.metaDefault, res.data);
                component.setState({ search1: newMeta.search, check: true });
                //component.props.setMeta({filter: newMeta.filter});
                //component.fetchData(newMeta);
                break;
            case 'changePage':
                newMeta = Object.assign({}, component.props.meta, res.data);
                if (component.props.meta.page !== newMeta.page) {
                    // component.props.setMeta({ page: newMeta.page });
                    component.fetchData(newMeta);
                }
                break;
            case 'changeSort':
                newMeta = Object.assign({}, component.props.meta, res.data);
                if (component.props.meta.sort !== newMeta.sort) {
                    component.props.setMeta({ sort: newMeta.sort });
                    component.fetchData(newMeta);
                }
                break;
            case 'changeKeySearch':
                newMeta = Object.assign({}, component.props.meta, res.data);
                component.props.setMeta({ search: newMeta.search });
                newMeta.page = 1;
                component.fetchData(newMeta);

                break;
            case 'changeFilter':

                newMeta = Object.assign({}, component.props.meta, res.data);
                if (component.state.check) {
                    newMeta.search = component.state.search1;
                }
                component.props.setMeta({ filter: newMeta.filter });
                newMeta.page = 1;
                component.fetchData(newMeta);

                break;
            default:
                break;
        }

    }
    // convert file to base64 su dung Blob
    async uint8ToString(buf) {
        if (buf?.size > 0) {
            var temp = await this.toBase64(buf);
            let url = URL.createObjectURL(base64toBlob(temp));
            if (url) {
                return url;
            }
            else {
                return '';
            }
        } else {
            return '';
        }

    }
    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    ChangeDLToAL(date) {
        debugger;
        if (date) {
            var d = new Date(date);            
            return http.get(API_URL+'api/common/dltoal/' + d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear());            
        }
        return '';
    }

}

export { BaseService };
