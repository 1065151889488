import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, InputGroup } from 'react-bootstrap';
import { FormSelect } from 'shared/components';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
class DynamicFieldsComponent extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.number]),
        setGetDMTen: PropTypes.bool, // check luu ma hay ten dm
    };
    constructor(props) {
        super(props);
        this.state = {
        };
        this.subscriptions = {};
    }
    handleFileChange(e) {
        this.props.onFileChange(e);
    }
    componentDidMount() {
        console.log(this.props+"123");
    }
    componentWillUnmount() {
        console.log(this.props + "123");
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
    upload() {
        document.getElementById("selectImage").click()
    }
    onChange() {
        //let res = selectedNodes.map(x => {
        //    return x.value;
        //});
        //let value = null;
        //if (['radioSelect', 'simpleSelect'].includes(this.props.mode)) {
        //    value = res[0] || null;
        //} else {
        //    value = res || null;
        //}
        //if (this.state.innerValue !== value) {
        //    setValue(this.state.innerValue, this.state.map, this.props.mode, false);
        //    setValue(value, this.state.map, this.props.mode, true);
        //}
        //this.setState({ innerValue: value }, () => {
        //    if (this.props.onChange) {
        //        this.props.onChange({
        //            persist: () => { },
        //            target: {
        //                type: 'change',
        //                id: this.props.id || null,
        //                name: this.props.name,
        //                value: value
        //            }
        //        });
        //    }
        //});
    }
    
    customFunc(e) {
         
        if (e.clipboardData.getData('Text') != null) {
            if (e.clipboardData.getData('Text').toLowerCase().includes('e')) {
            e.preventDefault();
            e.stopPropagation();
        }
    }
}
    handleBlur() {
        // this.setState({timeBlur:new Date()});
    }
    loadFields(product, index, values, handleChange, errors, handleBlur, touched, setFieldValue, Service, editMode, dataDanhMucs) {
        const formatChars = {
            n: '[0-1]',
            m: '[0-9]',
            e: '[0-3]',
            d: '[0-9]',
            z: '[1-2]',
            y: '[0-9]'
        }
        switch (product.kieuDuLieu) {
            //number
            case 1 :
                return (<Form.Row key={index}>
                    <Form.Group as={Col} md="12" controlId={product.tenTruong}>
                        <Form.Label>{product.label}{product.isRequired ? <Form.Label className="text-danger">(*)</Form.Label> : ''}</Form.Label>
                        {true ?
                            <React.Fragment>
                                <Form.Control
                                    disabled={!editMode}
                                    type="number"
                                    onPaste={(event) => this.customFunc(event)}
                                    name={product.tenTruong}
                                    value={values[product.tenTruong] == undefined || values[product.tenTruong] == null ? '' : values[product.tenTruong]}
                                    onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={/*touched[product.tenTruong] &&*/ !!errors[product.tenTruong]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors[product.tenTruong]}
                                </Form.Control.Feedback>
                            </React.Fragment> :
                            <p className="form-control-static  " >{values[product.tenTruong]}</p>
                        }
                    </Form.Group>
                </Form.Row>);
                break;
            case 5:
                return (<Form.Row key={index}>
                    <Form.Group as={Col} md="12" controlId={product.tenTruong}>
                        <Form.Label>{product.label}{product.isRequired ? <Form.Label className="text-danger">(*)</Form.Label> : ''}</Form.Label>
                        {true ?
                            <React.Fragment>
                                <Form.Control
                                    disabled={!editMode}
                                    type="number"
                                    onPaste={(event) => this.customFunc(event)}
                                    name={product.tenTruong}
                                    value={values[product.tenTruong] == undefined || values[product.tenTruong] == null ? '' : values[product.tenTruong]}
                                    onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={/*touched[product.tenTruong] &&*/ !!errors[product.tenTruong]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors[product.tenTruong]}
                                </Form.Control.Feedback>
                            </React.Fragment> :
                            <p className="form-control-static  " >{values[product.tenTruong]}</p>
                        }
                    </Form.Group>
                </Form.Row>);
                break;
            //text
            //text
            case 2:
                
                return (<Form.Row key={index}>
                    <Form.Group as={Col} md="12" controlId={product.tenTruong}>
                        <Form.Label>{product.label}{product.isRequired ? <Form.Label className="text-danger">(*)</Form.Label> : ''}</Form.Label>
                        {true ?
                            <React.Fragment>
                                <Form.Control
                                    disabled={!editMode}
                                    type="text"
                                    name={product.tenTruong}
                                    value={values[product.tenTruong] || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={/*touched[product.tenTruong] &&*/ !!errors[product.tenTruong]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors[product.tenTruong]}
                                </Form.Control.Feedback>
                            </React.Fragment> :
                            <p className="form-control-static  " >{values[product.tenTruong]}</p>
                        }
                    </Form.Group>
                </Form.Row>);
                break;
            //textarea
            case 3:
                return (<Form.Row key={index}>
                    <Form.Group as={Col} md="12" controlId={product.tenTruong}>
                        <Form.Label>{product.label}{product.isRequired ? <Form.Label className="text-danger">(*)</Form.Label> : ''}</Form.Label>
                        {true ?
                            <React.Fragment>
                                <Form.Control
                                    disabled={!editMode}
                                    as="textarea"
                                    onKeyPress={(ev) => { ev.stopPropagation(); }}
                                    name={product.tenTruong}
                                    value={values[product.tenTruong] || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={/*touched[product.tenTruong] &&*/ !!errors[product.tenTruong]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors[product.tenTruong]}
                                </Form.Control.Feedback>
                            </React.Fragment> :
                            <p className="form-control-static text-pre-wrap " >{values[product.tenTruong]}</p>
                        }
                    </Form.Group>
                </Form.Row>);
                break;
            //trường check box
            case 4:
                return (<Form.Row key={index}>
                    <Form.Group as={Col} md="12" className="d-flex">

                        {true ?
                            <React.Fragment>
                                <Form.Check
                                    disabled={!editMode}

                                    type='checkbox'
                                    onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                            setFieldValue([product.tenTruong], !values[product.tenTruong]);
                                        }
                                    }}
                                    label={product.label}
                                    name={product.tenTruong}
                                    value={true}
                                    onChange={handleChange}
                                    checked={values[product.tenTruong] == true}
                                />

                                {product.isRequired ? <Form.Label className="text-danger">(*)</Form.Label> : ''}
                            </React.Fragment>
                            : values[product.tenTruong] == true ? "Có" : "Không"}
                    </Form.Group>




                </Form.Row>);
                break;
            //dattime
            case 6:
                return (<Form.Row key={index}>
                    <Form.Group as={Col} md="12" controlId={product.tenTruong}>
                        <Form.Label>{product.label}{product.isRequired ? <Form.Label className="text-danger">(*)</Form.Label> : ''}</Form.Label>
                        {true ?
                            <InputGroup>
                                <InputMask
                                    className="form-control"
                                    formatChars={formatChars}
                                    alwaysShowMask={false}
                                    beforeMaskedValueChange={this.beforeMaskedValueChange}
                                    mask="ed/nm/zyyy"
                                    disabled={!editMode}
                                    type="text"
                                    name={product.tenTruong}
                                    value={values[product.tenTruong] ?Service.formatDateTimeTyping(values[product.tenTruong]):''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={/*touched[product.tenTruong] &&*/ !!errors[product.tenTruong]}

                                />
                                {/*<DatePicker*/}
                                {/*    disabled={!editMode}*/}
                                {/*    className={'form-control' +*/}
                                {/*        (errors[product.tenTruong] && touched[product.tenTruong] ? ' is-invalid' : '')}*/}
                                {/*    onChange={(date) => {*/}
                                {/*        setFieldValue([product.tenTruong], Service.formatDateTimeMDYdate(date));*/}
                                {/*    }}*/}

                                {/*    name={product.tenTruong}*/}
                                {/*    showMonthDropdown*/}
                                {/*    showYearDropdown*/}
                                {/*    dropdownMode="select"*/}
                                {/*    selected={values[product.tenTruong] ? new Date(values[product.tenTruong]) : null}*/}
                                {/*    dateFormat="dd/MM/yyyy"*/}
                                {/*/>*/}
                                <Form.Control.Feedback type="invalid">
                                    {errors[product.tenTruong]}
                                </Form.Control.Feedback>
                            </InputGroup> :
                            <p className="form-control-static">{Service.formatDateTime(values[product.tenTruong])}</p>
                        }
                    </Form.Group>
                </Form.Row >);
                break;
            //danh mục, selec danhmuc
            case 7:
                
                return (<Form.Row key={index}>
                    <Form.Group as={Col} md="12" controlId={product.tenTruong}>
                        <Form.Label>{product.label}{product.isRequired ? <Form.Label className="text-danger">(*)</Form.Label> : ''}</Form.Label>
                        {true ?
                            <React.Fragment>
                                <FormSelect
                                    disabled={!editMode}
                                    placeholder={"Chọn " + product.label}
                                    name={product.tenTruong}
                                    data={dataDanhMucs?.filter(x => x.quanLyDanhMuc_Id == product.quanLyDanhMuc_Id || x.quanLyDanhMuc_Id == product.danhMucId)}
                                    value={this.handleValueDM(values[product.tenTruong])}
                                    //onChange={handleChange}
                                    onChange={(e) => this.onChangeDM(e, setFieldValue, product.tenTruong, dataDanhMucs?.filter(x => x.quanLyDanhMuc_Id == product.quanLyDanhMuc_Id || x.quanLyDanhMuc_Id == product.danhMucId))}
                                    onBlur={handleBlur}
                                    isInvalid={/*touched[product.tenTruong] &&*/ !!errors[product.tenTruong]}
                                    mode="simpleSelect"
                                ></FormSelect>
                                <Form.Control.Feedback type="invalid">
                                    {errors[product.tenTruong]}
                                </Form.Control.Feedback>
                            </React.Fragment> :
                            <p className="form-control-static">{values.danhMuc}</p>
                        }
                    </Form.Group>
                </Form.Row>);
                break;
            default:
                break;
        }
    }
    onChangeDM = (e, setFieldValue, tentruong, datadm) => {
        
        let value = e.target.value;
        setFieldValue(tentruong, value);
        let strValue = this.props.setGetDMTen?datadm.find(x => x.id == value)?.tieuDe : datadm.find(x => x.id == value)?.ma;
        setFieldValue(tentruong + "Text", strValue);
    }
    handleValueDM(value) {
        
        if (value) {
            if (typeof value == 'string' && value.includes(";#")) {
                if(value.split(";#")[0]){
                    return parseInt(value.split(";#")[0], 10)
                }
             
            } else {
                return parseInt(value, 10) ;
            }
        } else {
            return 0;
        }

    }
    render() {
        
        const {
            dataDanhMucs,
            values,
            loaiTaiLieuFields,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
            service,
            editMode,
            touched} = this.props;
      
        return (
            <React.Fragment>
                {loaiTaiLieuFields.length > 0 ? loaiTaiLieuFields.map((product, index) => {
                    return this.loadFields(product, index, values, handleChange, errors, handleBlur, touched, setFieldValue, service, editMode, dataDanhMucs);
                }) : ''}
            </React.Fragment>
        );
    }
} 

export { DynamicFieldsComponent };
