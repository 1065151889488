import { createContext } from 'react';
import { BaseService } from 'shared/services';
import { Subject } from 'rxjs';
import { http } from 'shared/utils';
import { map } from 'rxjs/operators';
class HinhAnhService extends BaseService {
    constructor(props) {
        const _props = Object.assign({}, { url: 'pbimages' }, props);
        super(_props);
        this.sendToForm = new Subject();

    }
    getTreeFolderImage() {
        return http.get('api/folderimage/tree');
    }
    getAllThanhVien() {
        return http.get('api/thanhvien?page=1&itemsPerPage=-1&sortDesc=false').pipe(map((res) => {
            if (res.data) {
                return res.data.map(x => {
                    x.value = x.id;
                    x.label = x.tenDayDu;
                    return x;
                });
            }
        }));
    }
}
const _HinhAnhService = new HinhAnhService();
const Context = createContext();
export { Context, _HinhAnhService, HinhAnhService };