/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import { parseISO, format, parse } from 'date-fns';
registerLocale('vi', vi);
setDefaultLocale('vi');
class FormDateComponent extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        dateFormat: PropTypes.string,
        size: PropTypes.string,
        isValid: PropTypes.bool,
        isInvalid: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.array]),
        showTimeSelect: PropTypes.bool,
        showTimeSelectOnly: PropTypes.bool,
        // minDate:PropTypes.Date,
        // maxDate:PropTypes.Date,

    }
    constructor(props) {
        super(props);
        this.state = {
            value: this.processInValue(props.value)
        };
        this.subscriptions = {};

    }

    componentDidMount() {

    }
    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.processInValue(this.props.value) });
        }
    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
    processInValue(value) {
        if (typeof value === 'string') {
            const v = parseISO(value);
            return v != 'Invalid Date' ? v : null;
        }
        return value;
    }
    processOutValue(value) {
        if (value) {
            return format(value, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS');
        } else {
            return value;
        }

    }

    handleChange(value) {
        this.setState({ value });
        if (this.props.onChange) {
            this.props.onChange({
                persist: () => { },
                target: {
                    type: 'change',
                    id: this.props.id || null,
                    name: this.props.name,
                    value: this.processOutValue(value)
                }
            });
        }
    }
    handleBlur(ev) {
        console.log(ev);
    }
    onCalendarClose() {       
        let {value} =this.state;
        if (this.props.onBlur) {
            this.props.onBlur({
                persist: () => { },
                target: {
                    type: 'change',
                    id: this.props.id || null,
                    name: this.props.name,
                    value: this.processOutValue(value)
                }
            });
        }
    }
    render() {
        const {
            // eslint-disable-next-line react/prop-types 
            selected,
            value,
            className,
            size,
            isValid,
            isInvalid,
            onChange,
            dateFormat,
            showTimeSelect,
            showTimeSelectOnly,
            onBlur,
            ...props
        } = this.props;
        return (
            <div className={classNames('input-group', isValid && 'is-valid', isInvalid && 'is-invalid')}>
                <div className={classNames(
                    className,
                    'form-control date-picker',
                    size ? `form-control-${size}` : '',
                    isValid && 'is-valid',
                    isInvalid && 'is-invalid',

                )}>
                    <DatePicker
                        autoComplete='off'
                        className={className}
                        selected={this.state.value}
                        onChange={this.handleChange.bind(this)}
                        //onBlur={onBlur}                               
                        onCalendarClose={this.onCalendarClose.bind(this)}               
                        showTimeSelect={showTimeSelect}
                        showTimeSelectOnly={showTimeSelectOnly}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // popperPlacement={'bottom-start'}
                        placeholderText={dateFormat}
                        dateFormat={dateFormat}
                        minDate={this.props.minDate?this.props.minDate:null}
                        maxDate={this.props.maxDate?this.props.maxDate:null}
                        popperProps={{
                            positionFixed: true
                        }}
                        {...props}
                    >
                        {this.props.children}
                    </DatePicker>
                    <div className="input-group-append">
                        <div className="input-group-text" style={{marginTop: '2px' }}>
                            <span className="iconify" data-icon="mdi:calendar-clock-outline" data-inline="false"></span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { FormDateComponent };
