import * as OauthAction from './oauth/oauthAction';
import * as MenuHeThongAction from './menuhethong/menuhethongAction';
import * as permissionsAction from './quantrihethong/permissions/permissionsAction';
import * as groupsAction from './quantrihethong/groups/groupsAction';
import * as rolesAction from './quantrihethong/roles/rolesAction';
import * as usersAction from './quantrihethong/users/usersAction';
import * as documentsAction from './quantrihethong/documents/documentsAction';
import * as logsAction from './quantrihethong/logs/logsAction';
import * as DanhSachDanhMucAction from './danhsachdanhmuc/DanhSachDanhMucAction';
import * as TaiLieuAction from './tailieu/TaiLieuAction';
import * as CauHinhTaiLieuAction from './cauhinhtailieu/CauHinhTaiLieuAction';
import * as QuanLyDanhMucAction from './quanlydanhmuc/QuanLyDanhMucAction';
import * as DanhMucAction from './danhmuc/DanhMucAction';
import * as ConfigsAction from './configs/ConfigsAction';
import * as ConfigExcelAction from './configexcel/ConfigExcelAction';
import * as CauHinhDanhMucAction from './quanlydanhmuc/cauhinhdanhmuc/CauHinhDanhMucAction';
import * as ThongTinHoSoAction from './thongtinhoso/ThongTinHoSoAction';
import * as ConfigsObjectSendAction from './configsobjectsend/ConfigsObjectSendAction';
import * as HinhAnhAction from './hinhanh/HinhAnhAction';
import * as TrinhDoAction from './trinhdo/TrinhDoAction';
import * as QuocGiaAction from './danhmuc//quocgia/QuocGiaAction';
import * as DongHoAction from './danhmuc/dongho/DongHoAction';
import * as ThanhVienAction from './thanhvien/ThanhVienAction';
//{0}

export {
    OauthAction,
    permissionsAction,
    groupsAction,
    usersAction,
    rolesAction,
    MenuHeThongAction,
    documentsAction,
    logsAction,
    DanhSachDanhMucAction,
    TaiLieuAction,
    CauHinhTaiLieuAction,
    QuanLyDanhMucAction,
    DanhMucAction,
    HinhAnhAction,

    
    ConfigsAction,
    ConfigExcelAction,
    CauHinhDanhMucAction,
    ThongTinHoSoAction,
    ConfigsObjectSendAction,
    TrinhDoAction,
    QuocGiaAction,
    DongHoAction,
    ThanhVienAction,
    //{1}
};