import * as Constant from './permissionsConstant';

const permissions = {
    permissionsList: [],
    meta: {
        page: 1,
        page_size: 15,
        sort: {id:'asc'},
        search: '',
        filter: {                   
        },     
    }
};
const permissionsReducer = (state = permissions, action) => {
    switch (action.type) {
    case Constant.PERMISSIONS_SET_LIST_DATA:
        return Object.assign({}, state, { permissionsList: action.permissionsList });
    case Constant.PERMISSIONS_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {permissionsReducer};