import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { withRouter, matchPath } from 'react-router';



class TreeViewComponent extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            url: PropTypes.string,
            name: PropTypes.string,
            icon: PropTypes.string,
            children: PropTypes.array
        }).isRequired


    };
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };

    }
    componentDidMount() {        
        if (this.isActive()) {
            this.setState({ isOpen: true });
        }

    }
    handlerClickToggle(ev, childNum) {
        if (childNum > 0) {
            ev.preventDefault();
            this.setState({
                isOpen: !this.state.isOpen
            });
        }
    }
    isActive() {
        const { childrens } = this.props.data;
        if ((childrens || []).length > 0) {
            // eslint-disable-next-line react/prop-types
            const { pathname } = this.props.location;
            return childrens.map(x => {
                return matchPath(pathname, { path: x.url, exact: true, strict: true  });
            }).filter(x => x).length > 0;
        }
        return false;
    }
    render() {
        const {
            url,
            title,
            icon,
            childrens,
        } = this.props.data;
        const childNum = (childrens || []).length;
        return (
            <li className={
                classNames(
                    'nav-item',
                    childNum > 0 ? 'has-treeview' : '',
                    this.state.isOpen ? 'menu-open' : '')
            } >
                {childNum > 0 ?
                    <a href="/" className={
                        classNames(
                            'nav-link',
                            this.isActive() ? 'active' : '')}
                        onClick={(ev) => { this.handlerClickToggle(ev, childNum); }}>
                        <i className={`nav-icon ${icon}`} style={{}}></i>
                        <p>
                            {title}
                            <i className="right">
                                <span className="iconify" data-icon="fa-solid:angle-right" data-inline="fale"></span>
                            </i>
                            {/*<span className="badge badge-info right">{childNum}</span>*/}
                        </p>
                    </a>
                    :
                    <NavLink exact to={url} className="nav-link" >
                        <i className={`nav-icon ${icon}`} style={{}}></i>
                        <p>
                            {title}

                        </p>
                    </NavLink>
                }
                {childNum > 0 &&
                    <ul className="nav nav-treeview" >
                        {childrens.map((item, index) => {
                            return (
                                <TreeView key={index} data={item} />
                            );
                        })}
                    </ul>
                }
            </li>
        );
    }
}
const TreeView = withRouter(TreeViewComponent);
export { TreeView };




