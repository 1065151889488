import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
class ListBaseComponent extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.number]),
        
    };
    constructor(props) {
        super(props);
        this.state = {
        };
        this.subscriptions = {};
    }
   
    componentDidMount() {
        console.log(this.props);
    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
   
    handleBlur() {
        // this.setState({timeBlur:new Date()});
    }
    async DeleteAction(item, props, fetchData, ConfigExcelService, beh) {
        
        if (await beh.confirm('Bạn có muốn xóa bản ghi này')) {
            this.setState({
                loading: true
            });
            ConfigExcelService.del(item.id).subscribe(() => {
                toast.success('Xóa thành công');
                if (props.data.length === 1 && props.meta.page !== 1) {
                    props.setMeta({ page: props.meta.page - 1 });
                }
                fetchData(props.meta);
            });
            this.setState({
                loading: false
            });
        }

      


    }
}
export { ListBaseComponent };
