import * as Constant from './usersConstant';

const users = {
    usersList: [],
    meta: {
        page: 1,
        page_size: 15,
        sort: {id:'asc'},
        search: '',
        filter: {                   
        },     
    }
};
const usersReducer = (state = users, action) => {
    switch (action.type) {
    case Constant.USERS_SET_LIST_DATA:
        return Object.assign({}, state, { usersList: action.usersList });
    case Constant.USERS_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {usersReducer};