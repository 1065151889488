import React from 'react';
import PropTypes from 'prop-types';
import {Form, Col, InputGroup} from 'react-bootstrap';
import {FormSelect} from 'shared/components';
import DatePicker from 'react-datepicker';
class MultiDynamicFieldsComponents extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.number]),
    };
    constructor(props) {
        super(props);
        this.state = {
        };
        this.subscriptions = {};
    }
    handleFileChange(e) {
        this.props.onFileChange(e);
    }
    componentDidMount() {
        console.log(this.props);
    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
    upload() {
        document.getElementById("selectImage").click()
    }
    onChange() {
        //let res = selectedNodes.map(x => {
        //    return x.value;
        //});
        //let value = null;
        //if (['radioSelect', 'simpleSelect'].includes(this.props.mode)) {
        //    value = res[0] || null;
        //} else {
        //    value = res || null;
        //}
        //if (this.state.innerValue !== value) {
        //    setValue(this.state.innerValue, this.state.map, this.props.mode, false);
        //    setValue(value, this.state.map, this.props.mode, true);
        //}
        //this.setState({ innerValue: value }, () => {
        //    if (this.props.onChange) {
        //        this.props.onChange({
        //            persist: () => { },
        //            target: {
        //                type: 'change',
        //                id: this.props.id || null,
        //                name: this.props.name,
        //                value: value
        //            }
        //        });
        //    }
        //});
    }
    handleBlur() {
        // this.setState({timeBlur:new Date()});
    }
    loadFields(product, index, values, handleChange, errors, handleBlur, touched, setFieldValue, Service, editMode, dataDanhMucs, colwidth, indexValue) {
        var fieldchanges = values.FieldsChange;
        if (!fieldchanges )
            fieldchanges = "";
        
        switch (product.kieuDuLieu) {
            //number
            case 1 :
                return (
                    <Form.Group as={Col} md={colwidth} key={indexValue}>
                        {editMode ?
                            <React.Fragment>
                                <Form.Control
                                    disabled={true}
                                    className={fieldchanges.includes(',' + product.tenTruong+',') ?'ischanged':''}
                                    type="number"
                                    name={product.tenTruong}
                                    value={values[product.tenTruong] || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched[product.tenTruong] && !!errors[product.tenTruong]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors[product.tenTruong]}
                                </Form.Control.Feedback>
                            </React.Fragment> :
                            <p className="form-control-static  " >{values[product.tenTruong]}</p>
                        }
                    </Form.Group>
                );
                break;
            case  5:
                return (
                    <Form.Group as={Col} md={colwidth} key={indexValue}>
                        {editMode ?
                            <React.Fragment>
                                <Form.Control
                                    disabled={true}
                                    className={fieldchanges.includes(',' + product.tenTruong + ',') ? 'ischanged' : ''}
                                    type="number"
                                    name={product.tenTruong}
                                    value={values[product.tenTruong] || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched[product.tenTruong] && !!errors[product.tenTruong]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors[product.tenTruong]}
                                </Form.Control.Feedback>
                            </React.Fragment> :
                            <p className="form-control-static  " >{values[product.tenTruong]}</p>
                        }
                    </Form.Group>
                );
                break;
            //text
            case 2:
                return (
                    <Form.Group as={Col} md={colwidth} key={indexValue}>
                        {editMode ?
                            <React.Fragment>
                                <Form.Control
                                    disabled={true}
                                    className={fieldchanges.includes(',' + product.tenTruong + ',') ? 'ischanged' : ''}
                                    type="text"
                                    name={product.tenTruong}
                                    value={values[product.tenTruong] || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched[product.tenTruong] && !!errors[product.tenTruong]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors[product.tenTruong]}
                                </Form.Control.Feedback>
                            </React.Fragment> :
                            <p className="form-control-static  " >{values[product.tenTruong]}</p>
                        }
                    </Form.Group>
                );
                break;
            //textarea
            case 3:
                return (
                    <Form.Group as={Col} md={colwidth} key={indexValue}>
                        {editMode ?
                            <React.Fragment>
                                <Form.Control
                                    disabled={true}
                                    className={fieldchanges.includes(',' + product.tenTruong + ',') ? 'ischanged' : ''}
                                    as="textarea"
                                    name={product.tenTruong}
                                    value={values[product.tenTruong] || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched[product.tenTruong] && !!errors[product.tenTruong]}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors[product.tenTruong]}
                                </Form.Control.Feedback>
                            </React.Fragment> :
                            <p className="form-control-static text-pre-wrap " >{values[product.tenTruong]}</p>
                        }
                    </Form.Group>
                );
                break;
            //trường check box
            case 4:
                return (
                    <Form.Group as={Col} md="12" md={colwidth}  key={indexValue}>

                        {true ?
                            <React.Fragment>
                                <Form.Check
                                    disabled={true}
                                    className={fieldchanges.includes(',' + product.tenTruong + ',') ? 'ischanged' : ''}
                                    type='checkbox'

                                    label={product.label}
                                    name={product.tenTruong}
                                    value={true}
                                    onChange={handleChange}
                                    checked={values[product.tenTruong] == true}
                                />

                               
                            </React.Fragment>
                            : values[product.tenTruong] == true ? "Có" : "Không"}
                    </Form.Group>
                );
                break;
            case 11:
                return (
                    <Form.Group as={Col} md={colwidth} className="d-flex" key={indexValue}>
                        {editMode ? <React.Fragment>
                            <Form.Group as={Col} md="6" controlId="checkBox1">
                                <Form.Check
                                    disabled={true}
                                    custom
                                    type="radio"
                                    label="Có"
                                    name={product.tenTruong}
                                    value={1}
                                    onChange={handleChange}
                                    checked={values[product.tenTruong] == true}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="checkBox2" >
                                <Form.Check
                                    disabled={true}
                                    custom
                                    type="radio"
                                    label="Không"
                                    name={product.tenTruong}
                                    value={0}
                                    onChange={handleChange}
                                    checked={values[product.tenTruong] == false}
                                />
                            </Form.Group>
                        </React.Fragment> : values[product.tenTruong] == true ? "Có" : "Không"}
                    </Form.Group>
                );
                break;
            //dattime
            case 6:
                return (
                    <Form.Group as={Col} md={colwidth} key={indexValue}>
                        {editMode ?
                            <InputGroup>
                                <DatePicker
                                    disabled={true}
                                    className={(fieldchanges.includes(',' + product.tenTruong + ',') ? 'ischanged' : '') +' form-control' +
                                        (errors[product.tenTruong] && touched[product.tenTruong] ? ' is-invalid' : '')}
                                    onChange={(date) => {
                                        setFieldValue([product.tenTruong], Service.formatDateTimeMDYdate(date));
                                    }}
                                    name={product.tenTruong}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={values[product.tenTruong] ? new Date(values[product.tenTruong]) : null}
                                    dateFormat="dd/MM/yyyy"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors[product.tenTruong]}
                                </Form.Control.Feedback>
                            </InputGroup> :
                            <p className="form-control-static">{Service.formatDateTime(values[product.tenTruong])}</p>
                        }
                    </Form.Group>
                );
                break;
            //danh mục, selec danhmuc
            case 7:
                return (
                    <Form.Group as={Col} md={colwidth} key={indexValue}>
                        {editMode ?
                            <React.Fragment>
                                <FormSelect
                                    disabled={true}
                                    className={fieldchanges.includes(',' + product.tenTruong + ',') ? 'ischanged' : ''}
                                    placeholder={"Chọn " + product.label}
                                    name={product.tenTruong}
                                    data={dataDanhMucs?.filter(x => x.quanLyDanhMuc_Id == product.quanLyDanhMuc_Id)}
                                    value={this.handleValueDM(values[product.tenTruong])}
                                    //onChange={handleChange}
                                    onChange={(e) => this.onChangeDM(e, setFieldValue, product.tenTruong, dataDanhMucs?.filter(x => x.quanLyDanhMuc_Id == product.quanLyDanhMuc_Id))}
                                    onBlur={handleBlur}
                                    isInvalid={touched[product.tenTruong] && !!errors[product.tenTruong]}
                                    mode="simpleSelect"
                                ></FormSelect>
                                <Form.Control.Feedback type="invalid">
                                    {errors[product.tenTruong]}
                                </Form.Control.Feedback>
                            </React.Fragment> :
                            <p className="form-control-static">{values.danhMuc}</p>
                        }
                    </Form.Group>
                );
                break;
            default:
                break;
        }
    }
    onChangeDM = (e, setFieldValue, tentruong, datadm) => {
        let value = e.target.value;
        setFieldValue(tentruong, value);
        setFieldValue(tentruong + "Text", datadm.find(x => x.id == value)?.maDanhMuc);
    }
    handleValueDM(value) {
        if (value) {
            if (typeof value == 'string' && value.includes(";#")) {
                if(value.split(";#")[0]){
                    return parseInt(value.split(";#")[0], 10)
                }
             
            } else {
                return parseInt(value, 10) ;
            }
        } else {
            return 0;
        }

    }
    render() {
        const {
            dataDanhMucs,
            lstvalues,
            loaiTaiLieuFields,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
            service,
            editMode,
            colwidth,
            touched,
                    } = this.props;
        return (
            <React.Fragment>
                {loaiTaiLieuFields.length > 0 ? loaiTaiLieuFields.map((product, index1) => {
                    return (
                        <Form.Row key={index1}>


                            <Form.Group as={Col} md={colwidth==4?4:3} >
                                <Form.Label>{product.label}</Form.Label>
                            </Form.Group>


                            {
                                lstvalues.map((values, indexValue) => {
                                    return this.loadFields(product, index1, values, handleChange, errors, handleBlur, touched, setFieldValue, service, editMode, dataDanhMucs, colwidth,indexValue);
                                })
                            }
                        </Form.Row>
                    )
                }) : ''}
            </React.Fragment>
        );
    }
}
export {MultiDynamicFieldsComponents};
