import React from 'react';
import PropTypes from 'prop-types';
import { Image, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { authService } from 'shared/services';
import UserImg from 'assets/images/user2-160x160.jpg';
import Background from 'assets/images/Asset_3.png';
import logoNavBar from 'assets/images/logo-new.png';
import { Context, _usersService } from 'modules/quantrihethong/users/usersService';
import { UsersInfoFormComponent } from 'modules/quantrihethong/users/form/UsersInfoFormComponent';
import { ChangePassFormComponent } from 'modules/quantrihethong/users/form/ChangePassFormComponent';
class NavBar extends React.Component {
    logout() {
        authService.logout();
        window.location.href = '/';
    }

    static contextType = Context;
    componentDidMount() {
        if (!this.props.user.access_token)
            window.location.href = '/';
    }
    handleClose(res) {
        if (res) {
            this.setState({ loading: false });
        }
    }
    viewDetail() {
        _usersService.sendToFormAction.next({
            username: this.props.user.username,
            action: 'read',
            isShow: true
        });

    }
    changePassword() {
        _usersService.sendToFormChangePassword.next({
            id: this.props.user.id,
            action: 'changePass',
            isShow: true
        });

    }
    pushMenu() {
        var body = document.body;
        if (body.clientWidth < 768) {
            if (body.className.indexOf('sidebar-open') === -1) {
                body.className += ' sidebar-open';
            } else {
                body.className = body.className.replace(' sidebar-open', '');
            }
        }
    }
    viewEdit() {
        _usersService.sendToFormAction.next({
            username: this.props.user.username,
            action: 'edit',
            isShow: true
        });
    }

    render() {
        return (
            <nav className={'main-header navbar navbar-expand navbar-dark navbar-info'} style={{
                marginLeft: 0, backgroundImage: `url(${Background})`, height: 45, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", paddingLeft: 0, paddingRight: 0, borderTop: "5px solid #28a745"
            }}>
                <ul className="navbar-nav" style={{ flex: 1 }}>
                    <li className="nav-item d-sm-none d-block">
                        <div>
                            {/* eslint-disable-next-line*/}
                            <a className="nav-link" type="button" data-widget="pushmenu" onClick={this.pushMenu}><i className="fa fa-bars"></i></a>
                        </div>


                    </li>
                    <li className="nav-item pt-1 d-flex align-items-center w-100">
                        <div className="d-flex flex-column justify-content-center align-items-center nav-center" style={{ minWidth: 220 }}>
                            <Image src={logoNavBar} style={{ maxWidth: 150 }} />
                        </div>
                        <div className="w-100 titleNavbar">

                        </div>
                    </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                    <Dropdown as="li" className="nav-item user-menu">
                        {/* eslint-disable-next-line*/}
                        <Dropdown.Toggle as="a" className="nav-link" href="#">
                            <Image src={UserImg} className="user-image img-circle elevation-2" alt="User Image" />
                            <span className="d-none d-md-inline">{this.props.user.fullName}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu as="ul" className="dropdown-menu-sm" alignRight={true}>

                            <button className="dropdown-item" onClick={() => { this.viewDetail(); }} >
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                                Xem thông tin
                            </button>
                            <button className="dropdown-item" onClick={() => { this.viewEdit(); }} >
                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                                Sửa thông tin
                            </button>

                            <button className="dropdown-item" onClick={() => { this.changePassword(); }}>
                                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />
                                Đổi mật khẩu
                            </button>

                            <div className="dropdown-divider" />
                            <Link className="dropdown-item" onClick={this.logout} to="#" data-toggle="modal" data-target="#logoutModal">
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                                Đăng xuất
                            </Link>

                        </Dropdown.Menu>
                    </Dropdown>
                </ul>
                {/* <TaiKhoanFormComponent onClose={this.handleClose.bind(this)} />
                <UserFormChangePasswordComponent /> */}
                <UsersInfoFormComponent />
                <ChangePassFormComponent />
            </nav>


        );
    }
}
NavBar.propTypes = {
    onToggle: PropTypes.func,
    type: PropTypes.string,
    user: PropTypes.object
};
const mapStateToProps = (state) => {
    return {
        user: state.oauth
    };
};
const NavBarComponent = connect(mapStateToProps, {})(NavBar);
export { NavBarComponent };
