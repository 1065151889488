import * as Constant from './CauHinhTaiLieuConstant';
const CauHinhTaiLieu = {
    CauHinhTaiLieuList: [],
    meta: {
        page: 1,
        page_size: 15,
        sort: {stt:'asc'},
        search: '' ,
        filter: {
            lstCauHinhId:[]
        },
    }
};
const CauHinhTaiLieuReducer = (state = CauHinhTaiLieu, action) => {
    switch (action.type) {
        case Constant.CAUHINHTAILIEU_SET_LIST_DATA:
            return Object.assign({}, state, { CauHinhTaiLieuList: action.CauHinhTaiLieuList });
        case Constant.CAUHINHTAILIEU_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { CauHinhTaiLieuReducer};