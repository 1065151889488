import * as Constant from './QuanLyDanhMucConstant';
const QuanLyDanhMuc = {
    QuanLyDanhMucList: [],
    meta: {
        page: 1,
        page_size: 15,
        sort: {id:'desc'},
        search: '' ,
        filter: {
        },
    }
};
const QuanLyDanhMucReducer = (state = QuanLyDanhMuc, action) => {
    switch (action.type) {
        case Constant.QUANLYDANHMUC_SET_LIST_DATA:
            return Object.assign({}, state, { QuanLyDanhMucList: action.QuanLyDanhMucList });
        case Constant.QUANLYDANHMUC_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { QuanLyDanhMucReducer};