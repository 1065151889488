import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { LoadingComponent } from 'shared/components';
import { Context, _HinhAnhService } from './HinhAnhService';
import { HinhAnhListComponent } from './HinhAnhListComponent';
//import { HinhAnhFormComponent } from '../form/HinhAnhFormComponent';

class ModalImageComponent extends Component {
    static propTypes = {
        history: PropTypes.object,
        location: PropTypes.object,
        onClose: PropTypes.func,
    }
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            check: false,
            meta: {},
            loading: false,
            data: [],
            lstFolders: [],
            isShow: false,
            action: 'choose',
            folder: null
        };
        this.subscriptions = {};
    }
    componentDidMount() {
        _HinhAnhService.sendToForm.subscribe(res => {
            switch (res.action) {
                case 'choose':
                    this.setState({ isShow: res.isShow, action: res.action });
                    this.getFolder();
                    break;
            }
        });

    }
    getFolder() {
        _HinhAnhService.getTreeFolderImage().subscribe(res => {
            if (res)
                this.setState({ lstFolders: res, folder: res[0] });
        });
    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }

    handleChange(res) {
        _HinhAnhService.handleChange(res, this);
    }
    handleClose(res) {
        if (res) {
            this.fetchData(this.state.meta);
        }
    }
    addNew() {
        _HinhAnhService.sendToForm.next({
            id: null,
            action: 'new',
            isShow: true
        });
    }
    viewDetail(item) {
        _HinhAnhService.sendToForm.next({
            id: item.id,
            action: 'read',
            isShow: true
        });
    }
    viewEdit(item) {
        _HinhAnhService.sendToForm.next({
            id: item.id,
            action: 'edit',
            isShow: true
        });
    }
    onClose() {
        this.setState({ isShow: false });
    }
    async delete(item) {
        _HinhAnhService.DeleteAction(item, this);
    }
    onSelect(url) {
        this.setState({ isShow: false });
        if (this.props.onClose) {
            this.props.onClose(url);
        }
    }
    renderFolder(data) {
        let { folder } = this.state;
        return (
            data && data.filter(x => x.isShow === true).map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        <tr >
                            <td className={folder.id == item.id ? 'active' : ''}
                                style={{ paddingLeft: 20 * item.level, cursor: 'pointer' }}
                                onClick={() => this.loadImage(item)}>
                                {item.childrens.length > 0 ? (item.isOpen === true ? <i onClick={() => this.toggleDieuHuong(item)} className="fa fa-minus-square mr-1" aria-hidden="true"></i> : <i onClick={() => this.toggleDieuHuong(item)} className="fa fa-plus-square mr-1" aria-hidden="true"></i>) : null}
                                {item.title}
                            </td>
                        </tr>
                    </React.Fragment>

                );
            })
        );

    }
    toggleDieuHuong(item) {
        var dt = this.state.lstFolders.map(function (el) {
            if (el.id === item.id) {
                el.isOpen = !el.isOpen;
            }
            if (item.isOpen) {
                if (el.level === (item.level + 1) && el.parentId == item.id) {
                    el.isShow = true;
                }
            }
            else {
                if ((el.level > item.level) && el.parentId == item.id) {
                    el.isShow = false;
                    el.isOpen = false;
                }
            }
            return el;
        });
        this.setState({
            lstFolders: dt
        });
    }
    loadImage(item) {
        this.setState({ folder: item });
    }
    render() {
        let { lstFolders, folder } = this.state;
        return (
            <Modal size="xl" keyboard={false} backdrop="static" show={this.state.isShow} onHide={this.onClose.bind(this)}>
                <LoadingComponent loading={this.state.loading}></LoadingComponent>
                <Modal.Header closeButton>
                    <Modal.Title>Thư viện ảnh</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ minHeight: 300 }}>
                    <Row>
                        <Col md={3}>
                            <table className="table table-hover table-sm tree_folder">
                                <tbody>
                                    {
                                        lstFolders.length > 0 ? this.renderFolder(lstFolders) : ''
                                    }
                                </tbody>
                            </table>
                        </Col>
                        <Col>
                            {
                                folder && folder.id && <HinhAnhListComponent onSelect={this.onSelect.bind(this)} folder={folder} />
                            }
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {/* {this.state.editMode ?
                        <Button type="submit">Lưu lại</Button>
                        :
                        ''
                    } */}
                    <Button variant="default" onClick={() => { this.onClose(false); }}>
                        Đóng
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export { ModalImageComponent };