
import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Image } from 'react-bootstrap';
import { string, object } from 'yup';
import { Card, Form, InputGroup, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { LoadingComponent } from 'shared/components';
import { authService } from 'shared/services';
import { OauthAction } from 'redux/actions';
import '@iconify/iconify';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'styles/styles.scss';
import Menu from 'layouts/Menu';
import { HeaderComponent } from 'layouts/Header';
import Footer from 'layouts/Footer';

const schema = object({
    username: string().trim().required('Email không được để trống'),
    password: string().trim().required('Mật khẩu không được để trống'),
});

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                username: '',
                password: '',
            },
            loading: false,
            errorMessage: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
    }

    handleSubmit(data) {
        this.setState({ loading: true });

        authService.login(data).subscribe(() => {
            this.setState({ loading: false, errorMessage: '' });
            const { from } = { from: { pathname: '/ho-so' } };
            this.props.history.push(from);
        }, (err) => {
            // debugger;
            if (err.status === 400) {
                this.setState({ loading: false, errorMessage: 'Tài khoản hoặc mật khẩu không chính xác, vui lòng kiểm tra lại' });
            } else {
                this.setState({ loading: false, errorMessage: 'Có lỗi xảy ra trong quá trình login' });
            }
        });
    }

    render() {
        return (
            <div className="wrapper">
                <HeaderComponent />
                <Menu />
                <div id="content" className="pb-5" style={{ height: '64vh', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#fff', margin: '10px 0' }}>
                    <div className="container">

                        <div className="content-list">

                            <div className="row">
                                <div className="col-md-12 timeline pt-5">
                                    <div className="col-sm-6 col-sm-offset-3 bg-white" style={{ margin: 'auto' }}>

                                        <Formik
                                            validationSchema={schema}
                                            onSubmit={this.handleSubmit}
                                            // enableReinitialize={true}
                                            initialValues={this.state.data}
                                        >
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                handleBlur,
                                                values,
                                                touched,
                                                errors,
                                            }) => (

                                                <Form noValidate onSubmit={handleSubmit}>
                                                    <p className='text-center'
                                                        style={{
                                                            fontSize: '27px',
                                                            color: '#031e6d',
                                                            fontWeight: 'bold',
                                                            textTransform: 'uppercase',
                                                        }}
                                                    >Đăng Nhập</p>
                                                    <Form.Group>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                name="username"
                                                                placeholder="Email"
                                                                aria-label="Username"
                                                                autoComplete="on"
                                                                autoFocus
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.username}
                                                                isInvalid={touched.username && !!errors.username}
                                                            />
                                                            <InputGroup.Append>
                                                                <InputGroup.Text>
                                                                    <span className="iconify" data-icon="fa-solid:envelope" data-inline="false" />
                                                                </InputGroup.Text>
                                                            </InputGroup.Append>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.username}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                name="password"
                                                                type="password"
                                                                placeholder="Mật khẩu"
                                                                aria-label="Password"
                                                                autoComplete="on"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.password}
                                                                isInvalid={touched.password && !!errors.password}
                                                            />
                                                            <InputGroup.Append>
                                                                <InputGroup.Text>
                                                                    <span className="iconify" data-icon="fa:lock" data-inline="false" />
                                                                </InputGroup.Text>
                                                            </InputGroup.Append>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.password}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                    {this.state.errorMessage && <Row>
                                                        <Col className="col-12 text-center text-danger mb-3">
                                                            {this.state.errorMessage}
                                                        </Col>
                                                    </Row>}
                                                    <Row>

                                                        <Col className="col-12">
                                                            <button type="submit" className="btn btn-primary btn-block">Đăng nhập</button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

                <LoadingComponent loading={this.state.loading} />


            </div>

        );
    }
}
Login.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};
const LoginComponent = connect(null, { setToken: OauthAction.setToken })(Login);
export { LoginComponent };
