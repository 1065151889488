import * as Constants from './HinhAnhConstant';
export function setData(HinhAnhList) {
    return {
        type: Constants.HINHANH_SET_LIST_DATA,
        HinhAnhList
    };
}
export function setMeta(meta) {
    return {
        type: Constants.HINHANH_SET_LIST_META,
        meta
    };
}