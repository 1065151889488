import * as Constant from './ConfigExcelConstant';
const ConfigExcel = {
    ConfigExcelList: [],
    meta: {
        page: 1,
        page_size: 15,
        sort: {id:'desc'},
        search: '' ,
        filter: {
            loai: 0
        },
    }
};
const ConfigExcelReducer = (state = ConfigExcel, action) => {
    
    switch (action.type) {
        case Constant.CONFIGEXCEL_SET_LIST_DATA:
            return Object.assign({}, state, { ConfigExcelList: action.ConfigExcelList });
        case Constant.CONFIGEXCEL_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
        case "refreshMeta":
            return Object.assign(
                {},
                state,
                {
                    meta: Object.assign(
                        {},
                        state.meta,
                        {
                            page: 1,
                            page_size: 15,
                            sort: { id: 'desc' },
                            search: '',
                            filter: {
                                loai: 0
                            }
                        }
                    )
                });
    default:
        return state;
    }
};
export { ConfigExcelReducer};