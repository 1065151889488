import * as Constant from './DanhMucConstant';
const DanhMuc = {
    DanhMucList: [],
    meta: {
        page: 1,
        page_size: 15,
        sort: {id:'desc'},
        search: '' ,
        filter: {
        },
    }
};
const DanhMucReducer = (state = DanhMuc, action) => {
    switch (action.type) {
        case Constant.DANHMUC_SET_LIST_DATA:
            return Object.assign({}, state, { DanhMucList: action.DanhMucList });
        case Constant.DANHMUC_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { DanhMucReducer};