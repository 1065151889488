import * as Constant from './QuocGiaConstant';
const QuocGia = {
    QuocGiaList: [],
    meta: {
        page: 1,
        page_size: 15,
        sort: {id:'desc'},
        search: '' ,
        filter: {
        },
    }
};
const QuocGiaReducer = (state = QuocGia, action) => {
    switch (action.type) {
        case Constant.QUOCGIA_SET_LIST_DATA:
            return Object.assign({}, state, { QuocGiaList: action.QuocGiaList });
        case Constant.QUOCGIA_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { QuocGiaReducer};