import { createContext } from 'react';
import { BaseService } from 'shared/services';
import { Subject } from 'rxjs';
import { http } from 'shared/utils';
import { map } from 'rxjs/operators';
class usersService extends BaseService {
    constructor(props) {
        const _props = Object.assign({}, { url: 'api/users' }, props);
        super(_props);
        this.sendToForm = new Subject();
        this.sendToFormRP = new Subject();
        this.sendToFormChangePassword = new Subject();
        this.sendToFormAction = new Subject();
        
    }
    getAllGroups() {
        return http.get('api/groups').pipe(map((res) => {// khong lay ma nhom DN    
            const data=res.data;
            if (data) {
                return data.map(x => {
                    x.value = x.id;
                    x.label = x.title;
                    return x;
                });
            }
        }));
    }
    getByUserName(username){
        return http.get('api/users/username/'+username);
    }
    changePassword(data)
    {
        return http.put('api/users/password',data);
    }
    addUserToGroup(idUser, idGroup) {
        return http.post('api/groupuser', { groupId: idGroup, userId: idUser });
    }
    removeUserToGroup(idUser, idGroup) {
        return http.put('api/groupuser/remove',{ groupId: idGroup, userId: idUser });
    }
    activeUser(id) {
        return http.put(`api/users/active/${id}`);
    }
    deactiveUser(id) {
        return http.put(`api/users/deactive/${id}`);
    }
    resetPassword(data) {
        return http.put('api/users/resetpass', data);
    }
}

const Context = createContext();
const _usersService = new usersService();
export { Context, _usersService, usersService };

