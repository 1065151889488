import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { string, object, ref, number, array } from 'yup';
import { LoadingComponent, FormSelect, AnhDaiDien } from 'shared/components';
//import AvatarComponent from 'shared/components/form-control/AvatarComponent';
import { Context, _usersService } from '../usersService';
import { USERS_TITLE } from 'redux/quantrihethong/users/usersConstant';
//import { API_URL } from 'app-setting';
import { toast } from 'react-toastify';

const schema = object({
    fullName: string().trim().required('Bạn chưa nhập họ và tên').min(3, 'Bạn nhập tối thiểu 3 ký tự').max(100, 'Bạn nhập tối đa 100 ký tự').matches("^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\\s]+$", "Họ tên không chưa kí tự đặc biệt"),
    phoneNumber: string().trim().max(20, 'Bạn nhập tối đa 20 ký tự').min(6, 'Bạn nhập tối thiểu 6 ký tự').matches("^[0-9]+$", "Bạn chỉ nhập được số"),
    email: string().required('Bạn chưa nhập email').email('Email không đúng định dạng').nullable().min(3, 'Bạn nhập tối thiểu 3 ký tự').max(30, 'Bạn nhập tối đa 30 ký tự'),    
    sex: number().min(1, 'Bạn phải chọn giới tính'),
    username: string().trim().max(100, 'Bạn nhập tối đa 100 ký tự'),
    address: string().trim().max(500, 'Bạn nhập tối đa 500 ký tự'),
});
const userDefault = {
    username: '',
    password: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    groupIds: [],
    address: '',
    avatar: '',
    sex: 0,
};
class UsersInfoFormComponent extends React.Component {
    static propTypes = {
        onClose: PropTypes.func
    };
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            data: userDefault,
            dataGroups: [],
            dataSexs: [
                {
                    value: 1,
                    label: 'Nam'
                },
                {
                    value: 2,
                    label: 'Nữ'
                },
                {
                    value: 3,
                    label: 'Khác'
                },
            ],
            editMode: false,
            loading: false,
            isShow: false,
            action: 'new',
            username: null,
        };
        this.subscriptions = {};
    }
    componentDidMount() {
        _usersService.sendToFormAction.subscribe(res => {
            switch (res.action) {
                case 'edit':
                    this.setState({ editMode: true, isShow: res.isShow, action: res.action, username: res.username, loading: true, data: userDefault });
                    _usersService.getByUserName(res.username).subscribe(res => {
                        this.setState({
                            data: res,
                            loading: false,
                        });
                    });
                    break;
                case 'read':
                    this.setState({ editMode: false, isShow: res.isShow, action: res.action, username: res.username, loading: true, data: userDefault });
                    _usersService.getByUserName(res.username).subscribe(res => {
                        this.setState({
                            data: res,
                            loading: false,
                        });
                    });
                    break;
                default:
                    this.setState({ editMode: true, isShow: res.isShow, action: 'new', id: null, data: userDefault });
                    break;
            }
        });

    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
    onSubmit(data) {
        this.setState({
            loading: true
        });
        _usersService.update(data, data.id).subscribe(() => {
            toast.success('Cập nhật thành công');
            this.handleClose(true);
            this.setState({
                loading: false
            });
        },
            (err) => {

                this.setState({
                    loading: false
                });
                toast.error(err.error);
            });

    }
    onEdit() {
        this.setState({
            editMode: true
        });
    }
    handleClose(isRefesh) {
        this.setState({ isShow: false });
        if (this.props.onClose) {
            this.props.onClose(isRefesh);
        }

    }
    onKeyPress(ev) {
        const keyCode = ev.keyCode || ev.which;
        if (keyCode === 13) {
            ev.preventDefault();
            return false;
        }
    }
    computedTitle() {
        if (this.state.editMode) {
            return 'Chỉnh sửa thông tin ' + USERS_TITLE;
        }
        return 'Chi tiết ' + USERS_TITLE;
    }

    render() {
        return (
            <Modal size="xl" keyboard={false} backdrop="static" show={this.state.isShow} onHide={this.handleClose.bind(this)}>
                <LoadingComponent loading={this.state.loading}></LoadingComponent>
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => {
                        console.log(values);
                        this.onSubmit(values);
                    }}
                    enableReinitialize={true}
                    initialValues={this.state.data}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit} onKeyPress={(ev) => { ev.stopPropagation(); }}>
                            <Modal.Header closeButton>
                                <Modal.Title>{this.computedTitle()}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body onKeyPress={this.onKeyPress.bind(this)} >
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId="fullName">
                                        <Form.Label>Họ và tên</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                        {this.state.editMode ?
                                            <React.Fragment>
                                                <Form.Control
                                                    type="text"
                                                    name="fullName"
                                                    value={values.fullName || ''}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.fullName && !!errors.fullName}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.fullName}
                                                </Form.Control.Feedback>
                                            </React.Fragment> :
                                            <p className="form-control-static">{values.fullName}</p>
                                        }

                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="username">
                                        <Form.Label>Tên đăng nhập</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                        {this.state.editMode ?
                                            <React.Fragment>
                                                <Form.Control
                                                    type="text"
                                                    name="username"
                                                    readOnly={this.state.id !== null}
                                                    value={values.username || ''}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.username && !!errors.username}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.username}
                                                </Form.Control.Feedback>
                                            </React.Fragment> :
                                            <p className="form-control-static">{values.username}</p>
                                        }

                                    </Form.Group>
                                </Form.Row>                                
                                <Form.Row>

                                    <Form.Group as={Col} md="6" controlId="sex">
                                        <Form.Label>Giới tính</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                        {this.state.editMode ?
                                            <React.Fragment>
                                                <FormSelect
                                                    placeholder="Chọn giới tính"
                                                    name="sex"
                                                    data={this.state.dataSexs}
                                                    value={values.sex}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.sex && !!errors.sex}
                                                    mode="simpleSelect"
                                                ></FormSelect>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.sex}
                                                </Form.Control.Feedback>
                                            </React.Fragment> :
                                            <p className="form-control-static">{values.sex === 1 ? 'Nam' : values.sex === 2 ? 'Nữ' : values.sex === 3 ? 'Khác' : ''}</p>
                                        }
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId="phoneNumber">
                                        <Form.Label>Điện thoại</Form.Label>
                                        {this.state.editMode ?
                                            <React.Fragment>
                                                <Form.Control
                                                    type="text"
                                                    name="phoneNumber"
                                                    value={values.phoneNumber || ''}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.phoneNumber}
                                                </Form.Control.Feedback>
                                            </React.Fragment> :
                                            <p className="form-control-static">{values.phoneNumber}</p>
                                        }

                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="email">
                                        <Form.Label>Email</Form.Label>{this.state.editMode ? <Form.Label className="text-danger">(*)</Form.Label> : ""}
                                        {this.state.editMode ?
                                            <React.Fragment>
                                                <Form.Control
                                                    type="text"
                                                    name="email"
                                                    value={values.email || ''}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.email && !!errors.email}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </React.Fragment> :
                                            <p className="form-control-static">{values.email}</p>
                                        }
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId="address">
                                        <Form.Label>Địa chỉ</Form.Label>
                                        {this.state.editMode ?
                                            <React.Fragment>
                                                <Form.Control
                                                    type="text"
                                                    name="address"
                                                    value={values.address || ''}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.address && !!errors.address}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.address}
                                                </Form.Control.Feedback>
                                            </React.Fragment> :
                                            <p className="form-control-static">{values.address}</p>
                                        }

                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="3" controlId="avatar">
                                        <Form.Label>Ảnh đại diện</Form.Label>
                                        <React.Fragment>
                                            <AnhDaiDien
                                                editMode={this.state.editMode}
                                                className="rounded"
                                                name="avatar"
                                                value={values.avatar}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.avatar && !!errors.avatar}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.avatar}
                                            </Form.Control.Feedback>
                                        </React.Fragment>
                                        {/* {this.state.editMode ?
                                                <React.Fragment>
                                                    <AvatarComponent src={values.avatar ? `${API_URL}${values.avatar}` : ''} datacontrolimg="avatar" onChangeImage={(url) => { setFieldValue('avatar', url) }}  ></AvatarComponent>
                                                </React.Fragment>
                                                :
                                                <p className="form-control-static">
                                                    <img name="srcimage" width="150px" alt='' src={`${API_URL}${values.avatar}`}></img>
                                                </p>
                                            } */}
                                    </Form.Group>
                                </Form.Row>
                            </Modal.Body>
                            <Modal.Footer>
                                {this.state.editMode ?
                                    <Button type="submit">Lưu lại</Button>
                                    :
                                    ''
                                }
                                <Button variant="default" onClick={() => { this.handleClose(false); }}>
                                    Đóng
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>

        );
    }
}
export { UsersInfoFormComponent };