import * as Constant from './documentsConstant';

const documents = {
    documentsList: [],
    meta: {
        page: 1,
        page_size: 15,
        sort: {id:'asc'},
        search: '',
        filter: {                   
        },     
    }
};
const documentsReducer = (state = documents, action) => {
    switch (action.type) {
    case Constant.DOCUMENTS_SET_LIST_DATA:
        return Object.assign({}, state, { documentsList: action.documentsList });
    case Constant.DOCUMENTS_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {documentsReducer};