import { createContext } from 'react';
import { BaseService } from 'shared/services';
import { Subject } from 'rxjs';
import { http } from 'shared/utils';
import { map } from 'rxjs/operators';
import { API_URL } from 'app-setting';
class logsService extends BaseService {
    constructor(props) {
        const _props = Object.assign({}, { url: 'api/logs' }, props);
        super(_props);
        this.sendToForm = new Subject();
        this.sendToFormPermission = new Subject();
    }

    getAllPermissions() {
        return http.get(`api/permissions?page=1&itemsPerPage=-1&sortBy=Id&sortDesc=false`).pipe(map((res) => {// khong lay ma nhom DN    
            const data=res.data;
            if (data) {
                return data.map(x => {
                    x.value = x.id;
                    x.label = x.title;
                    return x;
                });
            }
        }));
    }
    getAllRoles() {
        return http.get(`api/roles?page=1&itemsPerPage=-1&sortBy=Id&sortDesc=false`).pipe(map((res) => {// khong lay ma nhom DN    
            const data=res.data;
            if (data) {
                return data.map(x => {
                    x.value = x.id;
                    x.label = x.title;
                    return x;
                });
            }
        }));
    }
    getGroupPerRoles() {
        return http.get(`api/grouprolepermission/permissions?page=1&itemsPerPage=-1&sortBy=Id&sortDesc=false`).pipe(map((res) => {// khong lay ma nhom DN    
            const data=res.data;
            if (data) {
                return data.map(x => {
                    x.value = x.id;
                    x.label = x.title;
                    return x;
                });
            }
        }));
    }
    getPermissionsRoles(groupId, roleId) {
        return http.get(`api/grouprolepermission/permissions?page=1&itemsPerPage=-1&sortBy=Id&sortDesc=false&GroupId=${groupId}&RoleId=${roleId}`);
    }
    updatePermission(data) {             
        return http.put(`${API_URL}api/grouprolepermission/updateper`, data);
    }
    
}

const Context = createContext();
const _logsService = new logsService();
export { Context, _logsService, logsService };

