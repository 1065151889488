import * as Constant from './TaiLieuConstant';
const TaiLieu = {
    TaiLieuList: [],
    meta: {
        page: 1,
        page_size: 15,
        sort: {TieuDe:'asc'},
        //sort: {id:'desc'},
        search: '' ,
        filter: {
          folderId:0,
phongId:0,
thoiGianBaoQuanId:0,
mucLucSoId:0,
hopSoId:0,
hoSoSoId:0,
ngayTao:'',
        },
    }
};
const TaiLieuReducer = (state = TaiLieu, action) => {
    switch (action.type) {
        case Constant.TAILIEU_SET_LIST_DATA:
            return Object.assign({}, state, { TaiLieuList: action.TaiLieuList });
        case Constant.TAILIEU_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { TaiLieuReducer};