import { loadable } from 'shared/utils';
const routes = [    
    {
        path: '/test',
        component: loadable(() => import('../modules/home'))
    },
    {
        path: '/danh-muc',
        component: loadable(() => import('../modules/danhmuc'))
    }, 
    {
        path: '/tin-tuc',
        component: loadable(() => import('../modules/danhmuc'))
    }, 
    {
        path: '/gioi-thieu',
        component: loadable(() => import('../modules/danhmuc'))
    }, 
    {
        path: '/tra-cuu',
        component: loadable(() => import('../modules/tracuu'))
    },
    {
        path: '/hoi-dap',
        component: loadable(() => import('../modules/hoidap'))
    },
    {
        path: '/lien-he',
        component: loadable(() => import('../modules/lienhe'))
    },
    {
        path: '/dang-ky-thong-tin',
        component: loadable(() => import('../modules/dangky'))
    },
    {
        path: '/xac-nhan',
        component: loadable(() => import('../modules/xacnhan'))
    },
    {
        path: '/tai-khoan',
        component: loadable(() => import('../modules/changpass'))
    },
    {
        path: '/ho-so',
        component: loadable(() => import('../modules/hoso'))
    },
    {
        path: '/',
        component: loadable(() => import('../modules/home'))
    },   
];
export { routes };