export const API_URL = window.appCfg.API_URL;
export const SSO = {
    WEB_ENDPOINT_SSO: "",
    URL_REDIRECT_SSO: "",
    URL_LOGOUT_SSO: "",
};
export const KEY_TOKEN_CAPCHAV3 = "6LcvzSEfAAAAAHNu40I7UyOIplx78N7Mvw1esEHc";
export const CLIENT = {
    client_id: '',
    client_secret: '',
};
export const AUTHORIZATION_BASE = '';

export const TYPE_TOAST = {
    SUCCESS: 'success',
    INFO: 'info',
    DANGER: 'danger',
    WARNING: 'warning',
    PRIMARY: 'primary',
    DARK: 'dark',
}

export const PermissonList = {
    NoPer: "1",
    OnlyPer: "2",
    AllPer: "3"
}
export const PrivilegeList = {
    ViewList: "01",
    ViewDetail: "02",
    ViewStatisticDetail: "03",
    ViewStatisticOverview: "04",
    Export: "05",
    ViewGarena: "06",
    Add: "07",
    Edit: "08",
    Delete: "09",
    Approved: "10",
    Hide: "11",
    Close: "12",
    TurnOff: "13",
    Import: "14",
}