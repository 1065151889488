import * as Constant from './ConfigsObjectSendConstant';
const ConfigsObjectSend = {
    ConfigsObjectSendList: [],
    meta: {
        page: 1,
        page_size: 15,
        sort: {id:'desc'},
        search: '' ,
        filter: {
        },
    }
};
const ConfigsObjectSendReducer = (state = ConfigsObjectSend, action) => {
    switch (action.type) {
        case Constant.CONFIGSOBJECTSEND_SET_LIST_DATA:
            return Object.assign({}, state, { ConfigsObjectSendList: action.ConfigsObjectSendList });
        case Constant.CONFIGSOBJECTSEND_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { ConfigsObjectSendReducer};